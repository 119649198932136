import {Navigate} from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account/Account';
import UserInfo from './pages/User/UserInfo';
import UserEdit from './pages/User/UserEdit';
import UserList from './pages/User/UserList';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import ChallengerList from "./pages/Challenger/ChallengerList";
import ChallengerEdit from "./pages/Challenger/ChallengerEdit";
import ChallengerScore from "./pages/Challenger/ChallengerScore";
import UserFirstGivList from "./pages/User/Givs/UserFirstGivList";
import UserSecondGivList from "./pages/User/Givs/UserSecondGivList";
import UserThirdGivList from "./pages/User/Givs/UserThirdGivList";
import TeamsList from "./pages/Teams/TeamsList";
import TeamsEdit from "./pages/Teams/TeamsEdit";
import TeamAdd from "./pages/Teams/TeamAdd";
import LegendList from "./pages/Legend/LegendList";
import LegendEdit from "./pages/Legend/LegendEdit";
import LegendScore from "./pages/Legend/LegendScore";
import СhampionsList from "./pages/Сhampions/СhampionsList";
import СhampionsEdit from "./pages/Сhampions/СhampionsEdit";
import Givs from "./pages/Givs/Givs";
import LanguageList from "./pages/Localization/Language/LanguageList";
import PhrasesList from "./pages/Localization/Phrases/PhrasesList";
import LanguageAdd from "./pages/Localization/Language/LanguageAdd";
import PhrasesEdit from "./pages/Localization/Phrases/PhrasesEdit";
import LanguageEdit from "./pages/Localization/Language/LanguageEdit";
import Logs from "./pages/Settings/Logs/Logs";
import Config from "./pages/Settings/Configs/Configs";
import ConfigsEdit from "./pages/Settings/Configs/ConfigsEdit";
import ResetPoints from "./pages/Settings/Points/ResetPoints";
import ConfigsAdd from "./pages/Settings/Configs/ConfigsAdd";


const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            {path: 'account', element: <Account/>},

            {path: 'user/:id', element: <UserInfo/>},
            {path: 'user/edit/:id', element: <UserEdit/>},
            {path: 'users', element: <UserList/>},

            {path: 'first-giv', element: <UserFirstGivList/>},
            {path: 'second-giv', element: <UserSecondGivList/>},
            {path: 'third-giv', element: <UserThirdGivList/>},

            {path: 'challenger', element: <ChallengerList/>},
            {path: 'challenger/edit', element: <ChallengerEdit/>},
            {path: 'challenger/score', element: <ChallengerScore/>},

            {path: 'legend', element: <LegendList/>},
            {path: 'legend/edit', element: <LegendEdit/>},
            {path: 'legend/score', element: <LegendScore/>},

            {path: 'champions', element: <СhampionsList/>},
            {path: 'champions/edit', element: <СhampionsEdit/>},

            {path: 'teams', element: <TeamsList/>},
            {path: 'teams/edit/:id', element: <TeamsEdit/>},
            {path: 'teams/add', element: <TeamAdd/>},

            {path: 'language', element: <LanguageList/>},
            {path: 'language/edit/:id', element: <LanguageEdit/>},
            {path: 'language/add', element: <LanguageAdd/>},

            {path: 'phrases', element: <PhrasesList/>},
            {path: 'phrases/edit/:id', element: <PhrasesEdit/>},

            {path: 'gives', element: <Givs/>},

            {path: 'logs', element: <Logs/>},
            {path: 'configs', element: <Config/>},
            {path: 'configs/edit/:id', element: <ConfigsEdit/>},
            {path: 'configs/add', element: <ConfigsAdd/>},
            {path: 'reset-points', element: <ResetPoints/>},


            {path: '/', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <Login/>},
            {path: '404', element: <NotFound/>},
            {path: '/', element: <Navigate to="/app/users"/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
