import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography, Divider, CardHeader, Grid, Alert, TextField
} from '@material-ui/core';
import {Link as RouterLink, Link} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import '../../styles/Stages/style.css'
import {BallTriangle} from "react-loader-spinner";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {useDelete, useGet, usePost} from "../../API/request";
import moment from 'moment';
import 'dayjs/locale/ru';
import {useConfirm} from "../../components/confirm";

const ChallengerList = () => {

    const getU = useGet()
    const postU = usePost();
    const deleteU =useDelete();
    const confirm = useConfirm();

    const [isLoaded, setIsLoaded] = useState(true);
    const [teams, setTeams] = useState([])

    const [forecastsStart, setForecastsStart] = useState()
    const [forecastsEnd, setForecastsEnd] = useState()
    const [tournamentEnd, setTournamentEnd] = useState()
    const [isOverStages, setIsOverStages] = useState()

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };

    const getTeams = () => {
        setIsLoaded(true)

        getU('stage/2')
            .then((response) =>{
                if (response.status === 'success'){
                    // console.log(response)
                    setTeams(response.data.teams)
                    setIsOverStages(response.data.time.event_over)
                    setForecastsStart(response.data.time.date_start_betting)
                    setForecastsEnd(response.data.time.date_start_event)
                    setTournamentEnd(response.data.time.date_end_event)
                }
            })
            .catch(() =>{

            })
            .finally(() =>{
                setIsLoaded(false)
            })

    }


    useEffect(() => {
        getTeams()
    }, []);

    const onConfirmResult = (id) => {

        confirm({
            title: 'Вы уверени что хотите завершить этап / Рассчитать результаты?',
            content: 'После подтверждения вы уже не сможете изменить счет команд',
            onConfirm: () => {
                postU(`admin/result/2`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно завершили этап');
                            getTeams();
                        }
                    })
                    .catch((e)=>{
                        showAlert('error', 'Произошла ошибка при попытке завершить этап');
                        console.log("opened")
                        console.log(e.response)
                    });
            }
        });
    };


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Легенды</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Легенды
                </Box>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                        <Box>
                            <Button
                                sx={{ml: 1, mb:1}}
                                color="error"
                                variant="contained"
                                onClick={onConfirmResult}
                                disabled={isOverStages}
                            >
                                Завершить этап / Рассчитать результат
                            </Button>
                            <Link to={"/app/legend/score"}>
                                <Button
                                    sx={{ml: 1, mb:1}}
                                    color="primary"
                                    variant="contained"

                                >
                                    Проставить счет
                                </Button>
                            </Link>
                            <Link to={"/app/legend/edit"}>
                                <Button
                                    sx={{ml: 1, mb:1}}
                                    color="primary"
                                    variant="contained"
                                >
                                    Редактировать
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Card>
                        <Box className="cardBlock">

                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Время начала и окончания турнира
                            </Typography>
                            <Divider/>
                            <Box className="cardBlockInputs">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                    <Stack spacing={3}>
                                        <DateTimePicker
                                            disabled
                                            onError={console.log}
                                            sx={{width: '100%'}}
                                            name="forecastsStart"
                                            variant="static"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            label="Старт прогнозов"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsStart}
                                            onChange={(newValue) => {
                                                setForecastsStart(newValue);
                                            }}
                                        />

                                        <DateTimePicker
                                            disabled
                                            sx={{minWidth: 300}}
                                            ampm={false}
                                            variant="static"
                                            // openTo="date"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            name="forecastsEnd"
                                            label="Начало турнира (завершение прогнозов)"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsEnd}
                                            onChange={(newValue) => {
                                                setForecastsEnd(newValue);
                                            }}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Box>
                        </Box>

                        <Box className="cardMainBlock">
                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Команды для участия
                            </Typography>
                            <Divider/>
                            <Box className="cardMainItems">
                                {
                                    teams <= 0
                                        ? <Box className="cardMainNoData">
                                            <Typography className="noDataFirstDesc">
                                                На данные момент список команд пуст.
                                            </Typography>
                                            <Typography className="noDataSecondDesc">
                                                Вам необходимо добавить команду
                                            </Typography>
                                        </Box>
                                        : teams?.map((item, index) =>
                                            <div className="itemWrapper">

                                                <img src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_logo}`} className="itemImg"/>


                                                <div className={item.passed ? "topPlaceTrue" : "topPlaceFalse"}/>


                                                <div className="itemTitle">
                                                    {
                                                        `${item.matches_win}:${item.matches_lose}`
                                                    }

                                                </div>
                                                <div className="itemTitle">
                                                    {
                                                        item.team_name
                                                    }
                                                </div>
                                            </div>
                                        )
                                }
                            </Box>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default ChallengerList;
