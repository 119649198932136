import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Button
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import UserFormEdit from '../../components/users/UserFormEdit';

const UserEdit = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Пользователи</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{ backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false} >
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <UserFormEdit id={String(id)}/>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UserEdit;
