import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import {useDelete, useGet, usePut} from '../../../API/request';
import {useConfirm} from "../../../components/confirm";
import {BallTriangle} from "react-loader-spinner";

const Config = () => {

    const getU = useGet();
    const putU = usePut();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [configs, setConfigs] = useState([]);
    const [recalculateData, setRecalculateData] = useState([]);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };

    const loadConfigs = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        getU('general/settings')
            .then((resp) => {
                if (resp.status === 'success') {
                    setConfigs(resp.data.settings);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setConfigs([]);
            })
            .finally(() => {
                setIsLoaded(false)
                setIsDataLoading(false);
            });

    };

    const phrasesForSettings = [
        "Обновление этапов, этапы сбрасываются, и их снова разраешается редактировать",
        "Удаление резульатов этапов(всех трех) который проставил админ",
        "Команды которые участвуют в этапе удаляются из него(удлаются не сами команды, а пул команд в этапе)",
        "Удаление ставок пользователей во всех трех этапах",
        "Удаление givoв у всех пользователей(юзеры получили золото, серебро, бронзу, эта информация удалится)",
        "У всех юзеров points сбросятся до 0"
    ]

    const phrasesForStage = [
        "Обновление этапа, этап сбрасываются, и его снова разраешается редактировать",
        "Удаление резульатов этапа который проставил админ",
        "Команды которые участвуют в этапе удаляются из него(удлаются не сами команды, а пул команд в этапе)",
        "Удаление ставок пользователей в данном этапе"
    ]

    const onResetAllSettings = () => {
        confirm({
            title: 'Вы уверены, что хотите сбросить все этапы?',
            content: 'Что вы удаляете/обновляете и не можете потом вернуть:',
            list: phrasesForSettings,
            onConfirm: () => {
                putU(`general/reset`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно сбросили все этапы');
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', 'Произошла ошибка при сбросе этапов');
                        console.log(e.response)
                    });
            }
        });
    };

    const onResetFirstStage = () => {
        confirm({
            title: 'Вы уверены, что хотите сбросить первый этап?',
            content: 'Что вы удаляете/обновляете и не можете потом вернуть:',
            list: phrasesForStage,
            warning: "Внимание: сброс этапа не очищает гивы (gives) и points пользователей, что может привести к их накоплению",
            onConfirm: () => {
                putU(`general/reset/stage/1`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно сбросили все этапы');
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', 'Произошла ошибка при сбросе этапов');
                        console.log(e.response)
                    });
            }
        });
    };
    const onResetSecondStage = () => {
        confirm({
            title: 'Вы уверены, что хотите сбросить второй этап?',
            content: 'Что вы удаляете/обновляете и не можете потом вернуть:',
            list: phrasesForStage,
            warning: "Внимание: сброс этапа не очищает гивы (gives) и points пользователей, что может привести к их накоплению",
            onConfirm: () => {
                putU(`general/reset/stage/2`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно сбросили все этапы');
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', 'Произошла ошибка при сбросе этапов');
                        console.log(e.response)
                    });
            }
        });
    };
    const onResetThirdStage = () => {
        confirm({
            title: 'Вы уверены, что хотите сбросить третий этап?',
            content: 'Что вы удаляете/обновляете и не можете потом вернуть:',
            list: phrasesForStage,
            warning: "Внимание: сброс этапа не очищает гивы (gives) и points пользователей, что может привести к их накоплению",
            onConfirm: () => {
                putU(`general/reset/stage/3`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно сбросили все этапы');
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', 'Произошла ошибка при сбросе этапов');
                        console.log(e.response)
                    });
            }
        });
    };

    const onFindProblemUsers = () => {
        confirm({
            title: 'Вы уверены, что хотите найти проблемных пользователей?',
            onConfirm: () => {
                getU(`general/problemUser`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы выполнили запрос, для просмотра проблемных пользователей перейдите в логи');
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', 'Произошла ошибка при сбросе этапов');
                        console.log(e.response)
                    });
            }
        });
    };

    const onDeleteSetting = (id) =>{
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить данную настройку?',
            onConfirm: () => {
                deleteU(`general/settings?id=${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadConfigs();
                        }
                    })
                    .catch((e)=>{
                        console.log(e.response)
                    });
            }
        });
    }


    useEffect(() => {
        loadConfigs();
    }, []);

    useEffect(() => {
        getU('general/getBeRecalculated')
            .then((response) => {
                console.log(response.data)
                let map = response.data.array.map((item) => item.name_russia);
                setRecalculateData(map)
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке взять данные для перерасчета');
            })
            .finally(() => {

            })
    }, [])


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Common settings</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Общие настройки
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                        <Box>
                            <Button
                                sx={{ml: 1, mb: 1, width: 250}}
                                color="primary"
                                variant="contained"
                                onClick={onFindProblemUsers}

                            >
                                Поиск проблемных пользователей
                            </Button>
                            <Link to={"/app/configs/add"}>
                                <Button
                                    sx={{ml: 1, mb: 1, width: 250}}
                                    color="primary"
                                    variant="contained"
                                >
                                    Добавить настройку
                                </Button>
                            </Link>
                            <Button
                                sx={{ml: 1, mb: 1, width: 250}}
                                color="primary"
                                variant="contained"
                                onClick={onResetAllSettings}

                            >
                                Сбросить все настройки
                            </Button>
                            <Button
                                sx={{ml: 1, mb: 1, width: 250}}
                                color="primary"
                                variant="contained"
                                onClick={onResetFirstStage}

                            >
                                Сбросить первый этап
                            </Button>
                            <Button
                                sx={{ml: 1, mb: 1, width: 250}}
                                color="primary"
                                variant="contained"
                                onClick={onResetSecondStage}

                            >
                                Сбросить второй этап
                            </Button>
                            <Button
                                sx={{ml: 1, mb: 1, width: 250}}
                                color="primary"
                                variant="contained"
                                onClick={onResetThirdStage}

                            >
                                Сбросить третий этап
                            </Button>
                        </Box>
                    </Box>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 600}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Наименование
                                                            </TableCell>
                                                            <TableCell>
                                                                Значение
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {configs.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell>
                                                                    {item.name_setting || "---"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.value || "---"}
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}}>
                                                                    <Link to={`/app/configs/edit/${item.id}`}>
                                                                        <Button color="primary" variant="contained">
                                                                            Редактирование
                                                                        </Button>
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}}>
                                                                    <Button
                                                                        color="error"
                                                                        variant="contained"
                                                                        onClick={() => onDeleteSetting(item.id)}
                                                                    >
                                                                        Удалить
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }

                </Container>
            </Box>
        </>
    );
};

export default Config;
