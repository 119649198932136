import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography, Divider, CardHeader, Grid, Alert, TextField
} from '@material-ui/core';
import {Link as RouterLink, Link, useNavigate} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import '../../styles/Stages/style.css'
import {BallTriangle} from "react-loader-spinner";
import ChevronLeft from "@material-ui/icons/ChevronLeft";


import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import 'dayjs/locale/ru';
import {useGet, usePost, usePut} from "../../API/request";

const Challenger = () => {

    const getU = useGet()
    const putU = usePut();
    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [teams, setTeams] = useState([])

    const [forecastsStart, setForecastsStart] = useState("2022-09-17T00:00")
    const [forecastsEnd, setForecastsEnd] = useState("2022-09-17T00:00")
    const [tournamentEnd, setTournamentEnd] = useState("2022-09-17T00:00")


    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3500);
    };

    const saveEdit = () => {
        setSubmitDisabled(true);

        let test = teams.filter(item => item.participatesLegends)

        let map = test.map((item) => item.id );

        const data = {
            teams: map,
            date_start_betting: forecastsStart,
            date_end_event: tournamentEnd,
            date_start_event: forecastsEnd
        }

        putU(`admin/stage/2`, data)
            .then((response) =>{
                if (response.status === "success"){
                    showAlert('success', 'Данные успешно обновленны');
                }
            })
            .catch((err) =>{
                console.log(err.response)
                if (err.response.data?.validation?.teams.includes("at least 9 items")) {
                    showAlert('error', 'Минимальное колличество команд: 9');
                } else if (err.response.data?.validation?.teams.includes("does not contain 1 required")) {
                    showAlert('error', 'Минимальное колличество команд: 9');
                } else if (err.response.data.message === "Не верно проставленный порядок времени") {
                    showAlert('error', 'Не верно проставленный порядок времени');
                } else if (err.response.data?.validation?.teams.includes("equal to 16 items")) {
                    showAlert('error', 'Максимальное количество команд: 16');
                }else if (err.response.data.message === "Нельзя изменить этап, если ставки уже начались") {
                    showAlert('error', 'Нельзя изменить этап, если ставки уже начались');
                } else if (err.response.data.message === "Результаты уже стоят") {
                    showAlert('error', 'Результаты уже были проставлены');
                } else if (err.response.data.message === "Этап уже завершен, результаты проставлены") {
                    showAlert('error', 'Этап уже завершен, результаты проставлены');
                } else {
                    showAlert('error', 'Ошибка сервера');
                }

            })
            .finally(() =>{
                setTimeout(()=>{
                    setSubmitDisabled(false);
                }, 3500)
            })
    }


    const getTeams = () => {
        setIsLoaded(true)

        getU('stage/2')
            .then((response) =>{
                if (response.status === 'success'){
                    setForecastsStart(response.data.time.date_start_betting)
                    setForecastsEnd(response.data.time.date_start_event)
                    setTournamentEnd(response.data.time.date_end_event)
                }
            })
            .catch(() =>{
                showAlert('error', 'Произошла ошибка при загрузке команд');
            })
            .finally(() =>{

            })

        getU('team?limit=36&page=1')
            .then((response) =>{
                if (response.status === 'success'){
                    console.log(response)
                    setTeams(response.data.teams)
                }
            })
            .catch(() =>{
                showAlert('error', 'Произошла ошибка при загрузке команд');
            })
            .finally(() =>{
                setIsLoaded(false)
            })

    }

    const changeState = (e) =>{
        let map = teams.map((items) => items.name === e.target.name ? {...items, participatesLegends : !items.participatesLegends} : items );
        setTeams(map)
    }

    useEffect(() => {
        getTeams()
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Редактирование</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Легенды
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Легенды</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{ marginBottom: 15, display: alert.isVisible ? 'flex' : 'none' }}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                        <Box onClick={() => console.log("click")}>

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={saveEdit}
                                disabled={submitDisabled}
                            >
                                Сохранить
                            </Button>


                        </Box>
                    </Box>
                    <Card>
                        <Box className="cardBlock">
                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Укажите время начала и окончания турнира
                            </Typography>
                            <Divider/>
                            <Box className="cardBlockInputs">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                    <Stack spacing={3}>
                                        <DateTimePicker
                                            onError={console.log}
                                            sx={{width: '100%'}}
                                            name="forecastsStart"
                                            variant="static"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            label="Старт прогнозов"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsStart}
                                            onChange={(newValue) => {
                                                setForecastsStart(newValue);
                                            }}
                                        />

                                        <DateTimePicker
                                            sx={{minWidth: 300}}
                                            ampm={false}
                                            variant="static"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            name="forecastsEnd"
                                            label="Начало турнира (завершение прогнозов)"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsEnd}
                                            onChange={(newValue) => {
                                                setForecastsEnd(newValue);
                                            }}
                                        />

                                    </Stack>
                                </LocalizationProvider>
                            </Box>
                        </Box>

                        <Box className="cardMainBlock">
                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Выберите команды для участия
                            </Typography>
                            <Divider/>
                            <Box className="cardMainItems">
                                {
                                    teams <= 0
                                        ? <Box className="cardMainNoData">
                                            <Typography className="noDataFirstDesc">
                                                На данные момент список команд пуст.
                                            </Typography>
                                            <Typography className="noDataSecondDesc">
                                                Перейдите в Настройки -> Добавить команды
                                            </Typography>
                                        </Box>
                                        : teams?.map((item, index) =>
                                            <div className="itemWrapper">

                                                <input
                                                    checked={item.participatesLegends}
                                                    onChange={(e) => changeState(e)}
                                                    name={item.name}
                                                    type="checkbox"
                                                    className="itemInput"
                                                />

                                                <img src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`} className="itemImg"/>

                                                <div className="itemTitle">
                                                    {
                                                        item.name
                                                    }
                                                </div>
                                            </div>

                                        )
                                }

                            </Box>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Challenger;
