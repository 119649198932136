import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography, Divider, CardHeader, Grid, Alert, TextField
} from '@material-ui/core';
import {Link} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import '../../styles/Stages/style.css'
import {BallTriangle} from "react-loader-spinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import {post, useDelete, useGet, usePost} from "../../API/request";
import {useConfirm} from "../../components/confirm";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const HampionsList = () => {

    const getU = useGet();
    const postU = usePost();
    const deleteU =useDelete();
    const confirm = useConfirm();

    const [isLoaded, setIsLoaded] = useState(true);

    const [forecastsStart, setForecastsStart] = useState()
    const [forecastsEnd, setForecastsEnd] = useState()
    const [tournamentEnd, setTournamentEnd] = useState()
    const [isOverStages, setIsOverStages] = useState()

    const [teams, setTeams] = useState([])

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };


    const getTeams = () => {
        setIsLoaded(true)

        getU('champions')
            .then((response) => {
                console.log(response)
                if (response.status === 'success') {
                    setTeams(response.data.grid)

                    setIsOverStages(response.data.stage.event_over)
                    setForecastsStart(response.data.stage.date_start_betting)
                    setForecastsEnd(response.data.stage.date_start_event)
                    setTournamentEnd(response.data.stage.date_end_event)
                }
            })
            .catch((e) => {
                console.log(e)
                showAlert('error', 'Произошла ошибка при загрузке команд');
            })
            .finally(() => {
                setIsLoaded(false)
            })

    }

    useEffect(() => {
        getTeams()
    }, []);

    const onConfirmResult = (id) => {

        confirm({
            title: 'Вы уверени что хотите завершить этап / Рассчитать результаты?',
            content: 'После подтверждения вы уже не сможете изменить счет команд',
            onConfirm: () => {
                postU(`admin/result/3/championsCalculate`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно завершили этап');
                            getTeams();
                        }
                    })
                    .catch((e)=>{
                        showAlert('error', 'Произошла ошибка при попытке завершить этап');
                        console.log("opened")
                        console.log(e.response)
                    });
            }
        });
    };


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Чемпионы
                </Box>
            </Box>


            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                        <Box>
                            <Button
                                sx={{ml: 1, mb:1}}
                                color="error"
                                variant="contained"
                                disabled={isOverStages}
                                onClick={onConfirmResult}
                            >
                                Завершить этап / Рассчитать результат
                            </Button>
                            <Link to={"/app/champions/edit"}>
                                <Button
                                    sx={{ml: 1, mb: 1}}
                                    color="primary"
                                    variant="contained"
                                >
                                    Редактировать
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                    <Card>
                        <Box className="cardBlock">

                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Время начала и окончания турнира
                            </Typography>
                            <Divider/>
                            <Box className="cardBlockInputs">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                    <Stack spacing={3}>
                                        <DateTimePicker
                                            disabled
                                            onError={console.log}
                                            sx={{width: '100%'}}
                                            name="forecastsStart"
                                            variant="static"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            label="Старт прогнозов"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsStart}
                                            onChange={(newValue) => {
                                                setForecastsStart(newValue);
                                            }}
                                        />

                                        <DateTimePicker
                                            disabled
                                            sx={{minWidth: 300}}
                                            ampm={false}
                                            variant="static"
                                            // openTo="date"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            name="forecastsEnd"
                                            label="Начало турнира (завершение прогнозов)"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsEnd}
                                            onChange={(newValue) => {
                                                setForecastsEnd(newValue);
                                            }}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Box>
                        </Box>

                        <Typography
                            color="textPrimary"
                            variant="h5"
                            className="cardBlockTitle"
                        >
                            Команды для участия
                        </Typography>

                        <Divider/>

                        <PerfectScrollbar>
                            <Box sx={{minWidth: 750}}>
                                {teams.length !== 0
                                    ? <div className="tournament">
                                        <div className="tournament__grid">

                                            <div className="tournament__round tournament__round--first-round">
                                                {
                                                    teams["1/4"]?.map((item) => (
                                                        <div className="tournament__match">
                                                            <div className="tournament__match__team">
                                                                {
                                                                    item.team_id_one
                                                                        ? <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_id_one.logo}`}
                                                                                alt=""/>
                                                                            <div>
                                                                                {item.team_id_one.name}
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <img
                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                alt=""/>
                                                                            <div>
                                                                                Пусто
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="tournament__match__team">
                                                                {
                                                                    item.team_id_two
                                                                        ? <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_id_two.logo}`}
                                                                                alt=""/>
                                                                            <div>
                                                                                {item.team_id_two.name}
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <img
                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                alt=""/>
                                                                            <div>
                                                                                Пусто
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <div className="tournament__round">
                                                {
                                                    teams["1/2"]?.map((item) => (
                                                        <div className="tournament__match">
                                                            <div className="tournament__match__team">
                                                                {
                                                                    item?.team_id_one
                                                                        ? <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item?.team_id_one?.logo}`}
                                                                                alt=""/>
                                                                            <div>
                                                                                {item?.team_id_one?.name}
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <img
                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                alt=""/>
                                                                            <div>
                                                                                Пусто
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="tournament__match__team">
                                                                {
                                                                    item?.team_id_two
                                                                        ? <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item?.team_id_two?.logo}`}
                                                                                alt=""/>
                                                                            <div>
                                                                                {item?.team_id_two?.name}
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <img
                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                alt=""/>
                                                                            <div>
                                                                                Пусто
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <div className="tournament__round tournament__round--final">
                                                {
                                                    teams["1/1"]?.map((item) => (
                                                        <div className="tournament__match">
                                                            <div className="tournament__match__team">
                                                                {
                                                                    item.team_id_one
                                                                        ? <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_id_one.logo}`}
                                                                                alt=""/>
                                                                            <div>
                                                                                {item.team_id_one.name}
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <img
                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                alt=""/>
                                                                            <div>
                                                                                Пусто
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div className="tournament__match__team">
                                                                {
                                                                    item?.team_id_two
                                                                        ? <>
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_id_two.logo}`}
                                                                                alt=""/>
                                                                            <div>
                                                                                {item.team_id_two.name}
                                                                            </div>
                                                                        </>
                                                                        : <>
                                                                            <img
                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                alt=""/>
                                                                            <div>
                                                                                Пусто
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>

                                            <div className="tournament__round tournament__round--winner">
                                                <div className="tournament__match">
                                                    <div className="tournament__match__team">
                                                        {
                                                            teams?.final?.team_id_winner
                                                                ? <>
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_URL}/uploads/${teams?.final?.team_id_winner?.logo}`}
                                                                        alt=""/>
                                                                    <div>
                                                                        {teams?.final?.team_id_winner.name}
                                                                    </div>
                                                                </>
                                                                : <>
                                                                    <img
                                                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                        alt=""/>
                                                                    <div>
                                                                        Пусто
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className="data_no_loaded">
                                        Данные не были загружены
                                    </div>

                                }

                            </Box>
                        </PerfectScrollbar>


                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default HampionsList;
