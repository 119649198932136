import {useEffect, useContext, useState} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    List,
    Typography
} from '@material-ui/core';
import {
    BarChart as BarChartIcon,
    Users as UsersIcon,
    Mail as MailIcon,
    DollarSign as Payment,
    Settings,
    Activity,
    ChevronUp,
    ChevronsUp,
    Gift,
    Shield,
    Grid,
    Bookmark,
    Book,
    List as ListIcon, PhoneForwarded
} from 'react-feather';
import NavItem from './NavItem';
import {useGet} from '../API/request';
import {useSelector} from 'react-redux';


const itemsDesign = [
    {
        icon: UsersIcon,
        title: 'Пользователи',
        list: [
            {
                href: '/app/users',
                icon: UsersIcon,
                title: 'Все пользователи'
            },
            {
                href: '/app/first-giv',
                icon: UsersIcon,
                title: 'Пользователи 1 гив'
            },
            {
                href: '/app/second-giv',
                icon: UsersIcon,
                title: 'Пользователи 2 гив'
            },
            {
                href: '/app/third-giv',
                icon: UsersIcon,
                title: 'Пользователи 3 гив'
            }
        ]
    },
    {
        href: '/app/challenger',
        icon: ChevronUp,
        title: 'Претенденты'
    },
    {
        href: '/app/legend',
        icon: ChevronsUp,
        title: 'Легенды'
    },
    {
        href: '/app/champions',
        icon: Shield,
        title: 'Чемпионы'
    },
    {
        href: '/app/teams',
        icon: Grid,
        title: 'Команды'
    },
    {
        href: '/app/gives',
        icon: Bookmark,
        title: 'Гивы'
    },
    {
        icon: Book,
        title: 'Локализация',
        list: [
            {
                href: '/app/language',
                icon: UsersIcon,
                title: 'Языки'
            },
            {
                href: '/app/phrases',
                icon: UsersIcon,
                title: 'Фразы'
            },
        ]
    }
];

const itemsSystem = [
    {
        icon: Settings,
        title: 'Настройки',
        list: [
            {
                href: '/app/configs',
                icon: Settings,
                title: 'Общие настройки'
            },
            {
                href: '/app/reset-points',
                icon: Settings,
                title: 'Сброс/пересчет очков'
            },
            {
                href: '/app/logs',
                icon: Settings,
                title: 'Логи'
            }
        ]
    },

];

const DashboardSidebar = ({onMobileClose, openMobile}) => {

    const location = useLocation();
    const [userAva, setUserAva] = useState('');
    const login = useSelector(state => state?.auth.user?.login)


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);


    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2
                }}
            >
                <Avatar
                    component={RouterLink}
                    src={userAva}
                    sx={{
                        cursor: 'pointer',
                        width: 64,
                        height: 64,
                        marginBottom: 1
                    }}
                    to="/app/account"
                />
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    {login || 'Admin'}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{px: 2}}>
                <List>
                    {itemsDesign.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            list={item?.list}
                        />
                    ))}
                </List>
            </Box>
            <Divider/>
            <Box sx={{px: 2}}>
                <List>
                    {itemsSystem.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            list={item?.list}
                        />
                    ))}
                </List>
            </Box>
            <Typography
                color="textPrimary"
                variant="body2"
                sx={{textAlign: 'center'}}
            >
                v{process.env.REACT_APP_VERSION} {process.env.REACT_APP_STATUS}
            </Typography>
            <Box sx={{flexGrow: 1}}/>
        </Box>
    );

    return (
        <>
            <Box sx={{display: {xs: 'block', lg: 'none'}}}>
                <Drawer
                    anchor="left"
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                    PaperProps={{
                        sx: {
                            width: 256
                        }
                    }}
                >
                    {content}
                </Drawer>

            </Box>
            <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                <Drawer
                    anchor="left"
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: 256,
                            top: 64,
                            height: 'calc(100% - 64px)'
                        }
                    }}
                >
                    {content}
                </Drawer>

            </Box>

        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default DashboardSidebar;
