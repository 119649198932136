import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";

const Givs = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [gives, setGives] = useState([]);

    const getU = useGet();

    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        getU('general/gives')
            .then((resp) => {
                if (resp.status === 'success') {
                    setGives(resp.data.gives)
                }
            })
            .catch((err) => {
                console.log(err.response)
            })
            .finally(() => {
                setIsDataLoading(false);
                setIsLoaded(false)
            });
    };

    useEffect(() => {
        loadUsers();
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Гивы
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 300}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Название
                                                            </TableCell>
                                                            <TableCell>
                                                                Очки
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {gives.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell>
                                                                    {item.give_name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `от ${item.min_points}-и поинтов`
                                                                    }
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default Givs;
