import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Alert,
    Box, Button, Card, CardContent, CardHeader,
    Container, Divider,
    Grid, TextField
} from '@material-ui/core';
import {usePut} from "../../API/request";
import {useDispatch} from "react-redux";
import {setUser} from "../../redux/slices/auth";

const Account = () => {

    const [values, setValues] = useState({
        password: '',
        confirm: '',
        currentPass: ''
    });
    const [errors, setErrors] = useState({
        password: false,
        confirm: false,
        currentPass: false
    });

    const putU = usePut();
    const dispatch = useDispatch();

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        if (nothingChanged === true) {
            setNothingChanged(false);
        }

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text, hideAlert = true) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        if (hideAlert) {
            setTimeout(() => {
                setAlert({
                    ...alert,
                    isVisible: false
                });

                setSubmitDisabled(false);

            }, 1400);
        }
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.password) {
            if (!values.password) {
                validComplete = false;
                formErrors.password = true;
            } else if (values.password.length < 6) {
                validComplete = false;
                formErrors.password = true;
            }

            if (values.password !== values.confirm) {
                validComplete = false;
                formErrors.confirm = true;
            }
        }

        if (!validComplete) {
            showAlert('error', 'Type correct data');
        }

        setErrors(formErrors);
        return validComplete;
    };

    const onSubmit = async () => {
        setSubmitDisabled(true);

        if (nothingChanged) {
            showAlert('error', 'Nothing changed');
            return;
        }

        if (validate()) {
            const data = {
                current_password: values.currentPass,
                new_password: values.password,
                repeat_new_password: values.confirm
            };

            putU(`admin/users`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                    } else {
                        showAlert('error', 'Error occured');
                    }
                })
                .catch((err) => {
                    showAlert('error', 'Server error occured');
                })
                .finally(() => {

                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Account</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth="lg">
                    <form
                        autoComplete="off"
                        noValidate
                    >
                        <Card>
                            <CardHeader title="Профиль"/>
                            <Divider/>
                            <CardContent>
                                <Grid container spacing={3} marginTop={0}>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Текущий пароль"
                                            name="currentPass"
                                            onChange={handleChange}
                                            required
                                            type="password"
                                            value={values.currentPass}
                                            variant="outlined"
                                            error={errors.currentPass}
                                            inputProps={{ maxLength: 16 }}
                                            helperText={errors.currentPass && 'Пароль должен быть больше 6 символов'}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Пароль"
                                            name="password"
                                            onChange={handleChange}
                                            required
                                            type="password"
                                            value={values.password}
                                            variant="outlined"
                                            error={errors.password}
                                            inputProps={{ maxLength: 16 }}
                                            helperText={errors.password && 'Пароль должен быть больше 6 символов'}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Повторите пароль"
                                            name="confirm"
                                            onChange={handleChange}
                                            type="password"
                                            required
                                            value={values.confirm}
                                            variant="outlined"
                                            error={errors.confirm}
                                            inputProps={{ maxLength: 16 }}
                                            helperText={errors.confirm && 'Пароли не совпадают'}
                                        />
                                    </Grid>
                                </Grid>
                                <Alert severity={alert.type}
                                       style={{display: alert.isVisible ? 'flex' : 'none', margin: '12px 0'}}>
                                    {alert.txt}
                                </Alert>
                            </CardContent>
                            <Divider/>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    p: 2
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={submitDisabled}
                                    onClick={onSubmit}
                                >
                                    Сохранить
                                </Button>
                            </Box>
                        </Card>
                    </form>
                </Container>
            </Box>
        </>
    )
}

export default Account;
