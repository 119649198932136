import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import {Image} from "@mui/icons-material";

const TeamsList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [teams, setTeams] = useState([]);

    const [filterName, setFilterName] = useState('');
    const [filterId, setFilterId] = useState('');
    const [filterLogin, setFilterLogin] = useState('');

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);


    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `team?&page=${page + 1}&limit=${limit}`;

        if (filterName !== '') {
            endpoint += `&name=${filterName}`;
        }

        if (filterLogin !== '') {
            endpoint += `&login=${filterLogin}`;
        }

        if (filterId !== '') {
            endpoint += `&id=${filterId}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTeams(resp.data.teams);
                    setCount(resp.data.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                setTeams([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() =>{
                setIsLoaded(false)
            });


    };

    const loadUsersWithArgParams = (searchParams) => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `team?&page=${page + 1}&limit=${limit}`;

        if (searchParams.name !== '') {
            endpoint += `&name=${searchParams.name}`;
        }

        if (searchParams.login !== '') {
            endpoint += `&login=${searchParams.login}`;
        }

        if (searchParams.id !== '') {
            endpoint += `&id=${searchParams.id}`;
        }


        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setTeams(resp.data.teams);
                    setCount(resp.data.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setTeams([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() =>{
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterEmail = (event) => {
        setFilterName(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                name: '',
                id: filterId,
                login: filterLogin
            });
        }
    };

    const handleFilter = () => {
        loadUsers();
    };

    const onDelete = (id) => {
        confirm({
            title: 'Удаление',
            content: 'Вы уверены, что хотите удалить команду?',
            onConfirm: () => {
                deleteU(`admin/team/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadUsers();
                        }
                    })
                    .catch((e)=>{
                        console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Команды
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="/app/teams/add">
                                            <Button color="primary" variant="contained">
                                                Добавить Команду
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 630}}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Название команды"
                                                        margin="normal"
                                                        name="filterEmail"
                                                        onChange={handleFilterEmail}
                                                        type="text"
                                                        value={filterName}
                                                        variant="outlined"
                                                        style={{marginRight: 10}}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilter}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Применить
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell >
                                                                Изображение
                                                            </TableCell>
                                                            <TableCell>
                                                                Название команды
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {teams.map((team) => (
                                                            <TableRow hover key={team.id}>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 50,
                                                                                maxHeight: { xs: 233, md: 167 },
                                                                                maxWidth: { xs: 350, md: 250 },
                                                                            }}
                                                                            alt="img"
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${team.logo}`}
                                                                        />

                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {team.name}
                                                                </TableCell>

                                                                <TableCell sx={{width: 100}}>
                                                                    <Link
                                                                        to={`/app/teams/edit/${team.id}`}
                                                                        style={team?.role?.role_id === 3 ? {pointerEvents: 'none'} : {}}>
                                                                        <Button color="primary"
                                                                                variant="contained"
                                                                                disabled={ team?.role?.role_id === 3}
                                                                        >
                                                                            Редактирование
                                                                        </Button>
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}}>
                                                                    <Button sx={{ ml: 1 }} color="primary" variant="contained" onClick={() => onDelete(team.id)}>
                                                                        Удалить
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={<span>Кол-во строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default TeamsList;
