import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {
    Avatar,
    CardContent,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Input,
    Box,
    Container,
    Button,
    Typography,
    Divider,
    CardHeader,
    Grid,
    Alert,
    TextField
} from '@material-ui/core';
import {Link as RouterLink, Link, useNavigate} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import '../../styles/Stages/style.css'
import {BallTriangle} from "react-loader-spinner";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import PerfectScrollbar from "react-perfect-scrollbar";
import {useGet, usePut} from "../../API/request";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

const Challenger = () => {

    const getU = useGet();
    const putU = usePut();

    const navigate = useNavigate();

    const [isLoaded, setIsLoaded] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(false);

    const [forecastsStart, setForecastsStart] = useState("2022-09-17T00:00")
    const [forecastsEnd, setForecastsEnd] = useState("2022-09-17T00:00")
    const [tournamentEnd, setTournamentEnd] = useState("2022-09-17T00:00")

    const [teams, setTeams] = useState([])

    const [values, setValues] = useState({
        firstOne: "Пусто",
        secondOne: "Пусто",

        firstTwo: "Пусто",
        secondTwo: "Пусто",

        firstThree: "Пусто",
        secondThree: "Пусто",

        firstFour: "Пусто",
        secondFour: "Пусто",

        firstFive: "Пусто",
        secondFive: "Пусто",

        firstSix: "Пусто",
        secondSix: "Пусто",

        firstSeven: "Пусто",
        secondSeven: "Пусто",

        eight: "Пусто",
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setButtonDisable(false);
        }, 3000);
    };


    const handleChange = (event) => {

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

    };

    const saveEdit = () => {
        setButtonDisable(true)

        const campaign = {
            "1/4": [
                {
                    id: 1,
                    team_id_one: values.firstOne === "Пусто" ? null : values.firstOne,
                    team_id_two: values.secondOne === "Пусто" ? null : values.secondOne,
                    team_id_passed: values.firstFive !== "Пусто" ? values.firstFive : null
                },
                {
                    id: 2,
                    team_id_one: values.firstTwo === "Пусто" ? null : values.firstTwo,
                    team_id_two: values.secondTwo === "Пусто" ? null : values.secondTwo,
                    team_id_passed: values.secondFive !== "Пусто" ? values.secondFive : null
                },
                {
                    id: 3,
                    team_id_one: values.firstThree === "Пусто" ? null : values.firstThree,
                    team_id_two: values.secondThree === "Пусто" ? null : values.secondThree,
                    team_id_passed: values.firstSix !== "Пусто" ? values.firstSix : null
                },
                {
                    id: 4,
                    team_id_one: values.firstFour === "Пусто" ? null : values.firstFour,
                    team_id_two: values.secondFour === "Пусто" ? null : values.secondFour,
                    team_id_passed: values.secondSix !== "Пусто" ? values.secondSix : null
                },
            ],
            "1/2": [
                {
                    id: 5,
                    team_id_one: values.firstFive === "Пусто" ? null : values.firstFive,
                    team_id_two: values.secondFive === "Пусто" ? null : values.secondFive,
                    team_id_passed: values.firstSeven !== "Пусто" ? values.firstSeven : null
                },
                {
                    id: 6,
                    team_id_one: values.firstSix === "Пусто" ? null : values.firstSix,
                    team_id_two: values.secondSix === "Пусто" ? null : values.secondSix,
                    team_id_passed: values.secondSeven !== "Пусто" ? values.secondSeven : null
                }
            ],
            "1/1": [
                {
                    id: 7,
                    team_id_one: values.firstSeven === "Пусто" ? null : values.firstSeven,
                    team_id_two: values.secondSeven === "Пусто" ? null : values.secondSeven,
                    team_id_passed: values.eight !== "Пусто" ? values.eight : null
                }
            ],
        }

        const data = {
            campaign,
            times: {
                date_start_betting: forecastsStart,
                date_end_event: tournamentEnd,
                date_start_event: forecastsEnd
            }
        }

        putU('admin/championsGrid', data)
            .then((response) => {
                if (response.status === 'success') {
                    showAlert('success', 'Данные успешно обновленны');
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.response.data.message === "Этап уже завершен, результаты проставлены"){
                    showAlert('error', 'Этап уже завершен, результаты проставлены');
                } else if (err.response.data.message === "Не верно проставленный порядок времени") {
                    showAlert('error', 'Не верно проставленный порядок времени');
                } else {
                    showAlert('error', 'Ошибка сервера');
                }
            })
            .finally(() => {

            })
    }

    const getTeams = async () => {
        setIsLoaded(true)

        await getU('team?limit=36&page=1')
            .then((response) => {
                setTeams(response.data.teams)

            })
            .catch((e) => {
                showAlert('error', 'Произошла ошибка при загрузке команд');
            })
            .finally(() => {

            })

        await getU('champions')
            .then((response) => {

                const teams = response.data.grid;

                const state = {
                    firstOne: teams["1/4"].find((item) => item.id === 1)?.team_id_one
                        ? teams["1/4"].find((item) => item.id === 1)?.team_id_one?.id
                        : "Пусто",
                    secondOne: teams["1/4"].find((item) => item.id === 1)?.team_id_two
                        ? teams["1/4"].find((item) => item.id === 1)?.team_id_two?.id
                        : "Пусто",

                    firstTwo: teams["1/4"].find((item) => item.id === 2)?.team_id_one
                        ? teams["1/4"].find((item) => item.id === 2)?.team_id_one?.id
                        : "Пусто",
                    secondTwo: teams["1/4"].find((item) => item.id === 2)?.team_id_two
                        ? teams["1/4"].find((item) => item.id === 2)?.team_id_two?.id
                        : "Пусто",

                    firstThree: teams["1/4"].find((item) => item.id === 3)?.team_id_one
                        ? teams["1/4"].find((item) => item.id === 3)?.team_id_one?.id
                        : "Пусто",
                    secondThree: teams["1/4"].find((item) => item.id === 3)?.team_id_two
                        ? teams["1/4"].find((item) => item.id === 3)?.team_id_two?.id
                        : "Пусто",

                    firstFour: teams["1/4"].find((item) => item.id === 4)?.team_id_one
                        ? teams["1/4"].find((item) => item.id === 4)?.team_id_one?.id
                        : "Пусто",
                    secondFour: teams["1/4"].find((item) => item.id === 4)?.team_id_two
                        ? teams["1/4"].find((item) => item.id === 4)?.team_id_two?.id
                        : "Пусто",

                    firstFive: teams["1/2"].find((item) => item.id === 5)?.team_id_one
                        ? teams["1/2"].find((item) => item.id === 5)?.team_id_one?.id
                        : "Пусто",
                    secondFive: teams["1/2"].find((item) => item.id === 5)?.team_id_two
                        ? teams["1/2"].find((item) => item.id === 5)?.team_id_two?.id
                        : "Пусто",

                    firstSix: teams["1/2"].find((item) => item.id === 6)?.team_id_one
                        ? teams["1/2"].find((item) => item.id === 6)?.team_id_one?.id
                        : "Пусто",
                    secondSix: teams["1/2"].find((item) => item.id === 6)?.team_id_two
                        ? teams["1/2"].find((item) => item.id === 6)?.team_id_two?.id
                        : "Пусто",

                    firstSeven: teams["1/1"].find((item) => item.id === 7)?.team_id_one
                        ? teams["1/1"].find((item) => item.id === 7)?.team_id_one?.id
                        : "Пусто",
                    secondSeven: teams["1/1"].find((item) => item.id === 7)?.team_id_two
                        ? teams["1/1"].find((item) => item.id === 7)?.team_id_two?.id
                        : "Пусто",

                    eight: teams.final.team_id_winner
                        ? teams.final.team_id_winner.id
                        : "Пусто",
                }

                setForecastsStart(response.data.stage.date_start_betting)
                setForecastsEnd(response.data.stage.date_start_event)
                setTournamentEnd(response.data.stage.date_end_event)

                setValues(state)

            })
            .catch((e) => {
                console.log(e)
                showAlert('error', 'Произошла ошибка при загрузке команд');
            })
            .finally(() => {

            })

        await setIsLoaded(false)
    }



    useEffect(() => {
        getTeams();
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Чемпионы
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Чемпионы</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>


            <Box sx={{backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                        <Box>

                            <Button
                                disabled={buttonDisable}
                                color="primary"
                                variant="contained"
                                onClick={saveEdit}
                            >
                                Сохранить
                            </Button>


                        </Box>
                    </Box>
                    <Card>
                        <Box className="cardBlock">
                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Укажите время начала и окончания турнира
                            </Typography>
                            <Divider/>
                            <Box className="cardBlockInputs">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                                    <Stack spacing={3}>
                                        <DateTimePicker
                                            onError={console.log}
                                            sx={{width: '100%'}}
                                            name="forecastsStart"
                                            variant="static"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            label="Старт прогнозов"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsStart}
                                            onChange={(newValue) => {
                                                setForecastsStart(newValue);
                                            }}
                                        />

                                        <DateTimePicker
                                            sx={{minWidth: 300}}
                                            ampm={false}
                                            variant="static"
                                            inputFormat="DD.MM.YYYY HH:mm"
                                            name="forecastsEnd"
                                            label="Начало турнира (завершение прогнозов)"
                                            minDate={dayjs('2022-01-01T00:00')}
                                            maxDate={dayjs('2025-12-31T23:59')}
                                            minTime={dayjs('2022-01-01T00:00')}
                                            maxTime={dayjs('2025-12-31T23:59')}
                                            renderInput={(props) => <TextField {...props} />}
                                            value={forecastsEnd}
                                            onChange={(newValue) => {
                                                setForecastsEnd(newValue);
                                            }}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            className="cardBlockTitle"
                        >
                            Выберите Команды для участия
                        </Typography>

                        <Divider/>
                        <PerfectScrollbar>
                            <Box sx={{minWidth: 850}}>
                                <div className="tournament">
                                    <div className="tournament__grid">

                                        <div className="tournament__round tournament__round--first-round">
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstOne">1/4</InputLabel>
                                                    <Select
                                                        labelId="firstOne"
                                                        name="firstOne"
                                                        value={values.firstOne}
                                                        label="1/4"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem
                                                                    value={item.id}
                                                                    disabled={
                                                                        item.id === values.firstOne ||
                                                                        item.id === values.secondOne ||
                                                                        item.id === values.firstTwo ||
                                                                        item.id === values.secondTwo ||
                                                                        item.id === values.firstThree ||
                                                                        item.id === values.secondThree ||
                                                                        item.id === values.firstFour ||
                                                                        item.id === values.secondFour
                                                                    }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondOne">1/4</InputLabel>
                                                    <Select
                                                        labelId="secondOne"
                                                        name="secondOne"
                                                        value={values.secondOne}
                                                        label="1/4"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstTwo">1/4</InputLabel>
                                                    <Select
                                                        labelId="firstTwo"
                                                        name="firstTwo"
                                                        value={values.firstTwo}
                                                        label="firstTwo"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondTwo">1/4</InputLabel>
                                                    <Select
                                                        labelId="secondTwo"
                                                        name="secondTwo"
                                                        value={values.secondTwo}
                                                        label="secondTwo"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstThree">1/4</InputLabel>
                                                    <Select
                                                        labelId="firstThree"
                                                        name="firstThree"
                                                        value={values.firstThree}
                                                        label="firstThree"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondThree">1/4</InputLabel>
                                                    <Select
                                                        labelId="secondThree"
                                                        name="secondThree"
                                                        value={values.secondThree}
                                                        label="secondThree"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstFour">1/4</InputLabel>
                                                    <Select
                                                        labelId="firstFour"
                                                        name="firstFour"
                                                        value={values.firstFour}
                                                        label="firstFour"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondFour">1/4</InputLabel>
                                                    <Select
                                                        labelId="secondFour"
                                                        name="secondFour"
                                                        value={values.secondFour}
                                                        label="secondFour"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.map((item) => (
                                                                <MenuItem value={item.id}
                                                                          disabled={
                                                                              item.id === values.firstOne ||
                                                                              item.id === values.secondOne ||
                                                                              item.id === values.firstTwo ||
                                                                              item.id === values.secondTwo ||
                                                                              item.id === values.firstThree ||
                                                                              item.id === values.secondThree ||
                                                                              item.id === values.firstFour ||
                                                                              item.id === values.secondFour
                                                                          }
                                                                >
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="tournament__round">
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstFive">1/2</InputLabel>
                                                    <Select
                                                        labelId="firstFive"
                                                        name="firstFive"
                                                        value={values.firstFive}
                                                        label="firstFive"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {teams.filter((item) => item.id === values.firstOne || item.id === values.secondOne).map((item) => (
                                                            <MenuItem value={item.id}>
                                                                <div className="tournament__itemContainer">
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                        alt=""
                                                                        className="menuItemImg"/>
                                                                    {
                                                                        item.name
                                                                    }
                                                                </div>
                                                            </MenuItem>
                                                        ))
                                                        }

                                                    </Select>
                                                </FormControl>

                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondFive">1/2</InputLabel>
                                                    <Select
                                                        labelId="secondFive"
                                                        name="secondFive"
                                                        value={values.secondFive}
                                                        label="secondFive"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.filter((item) => item.id === values.firstTwo || item.id === values.secondTwo).map((item) => (
                                                                <MenuItem value={item.id}>
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstSix">1/2</InputLabel>
                                                    <Select
                                                        labelId="firstSix"
                                                        name="firstSix"
                                                        value={values.firstSix}
                                                        label="firstSix"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.filter((item) => item.id === values.firstThree || item.id === values.secondThree).map((item) => (
                                                                <MenuItem value={item.id}>
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondSix">1/2</InputLabel>
                                                    <Select
                                                        labelId="secondSix"
                                                        name="secondSix"
                                                        value={values.secondSix}
                                                        label="secondSix"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.filter((item) => item.id === values.firstFour || item.id === values.secondFour).map((item) => (
                                                                <MenuItem value={item.id}>
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="tournament__round tournament__round--final">
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstSeven">Финал</InputLabel>
                                                    <Select
                                                        labelId="firstSeven"
                                                        name="firstSeven"
                                                        value={values.firstSeven}
                                                        label="firstSeven"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.filter((item) => item.id === values.firstFive || item.id === values.secondFive).map((item) => (
                                                                <MenuItem value={item.id}>
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="secondSeven">Финал</InputLabel>
                                                    <Select
                                                        labelId="secondSeven"
                                                        name="secondSeven"
                                                        value={values.secondSeven}
                                                        label="secondSeven"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.filter((item) => item.id === values.firstSix || item.id === values.secondSix).map((item) => (
                                                                <MenuItem value={item.id}>
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="tournament__round tournament__round--winner">
                                            <div className="tournament__match">
                                                <FormControl fullWidth className="tournament__match__teamE"
                                                             size="small">
                                                    <InputLabel id="firstEight">Победитель</InputLabel>
                                                    <Select
                                                        labelId="Победитель"
                                                        name="eight"
                                                        value={values.eight}
                                                        label="Победитель"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Пусто">
                                                            <div className="tournament__itemContainer">
                                                                Пусто
                                                            </div>
                                                        </MenuItem>
                                                        {
                                                            teams.filter((item) => item.id === values.firstSeven || item.id === values.secondSeven).map((item) => (
                                                                <MenuItem value={item.id}>
                                                                    <div className="tournament__itemContainer">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                            alt=""
                                                                            className="menuItemImg"/>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Box>
                        </PerfectScrollbar>

                    </Card>

                </Container>
            </Box>
        </>
    );
};

export default Challenger;
