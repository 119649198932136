import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, Typography, Input, TextField, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import UserFormEdit from '../../../components/users/UserFormEdit';
import {useGet, usePut} from "../../../API/request";
import {BallTriangle} from "react-loader-spinner";

const LanguageEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
    const [values, setValues] = useState({
        name: '',
        codeName: '',
        flag: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        codeName: false,
        flag: false
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const [dataBeforeChange, setDataBeforeChange] = useState({});

    const imgUploaded = (event) => {
        setNothingChanged(false);
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            flag: event.target.files[0]
        });
    };

    const handleChange = (event) => {
        setNothingChanged(false);

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name === '') {
            validComplete = false;
            formErrors.name = true;
        }
        if (values.codeName === '') {
            validComplete = false;
            formErrors.codeName = true;
        }


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);
            setIsShowLoader(true);

            let data = new FormData();


            if (dataBeforeChange.name !== values.name){
                data.append("name", values.name);
            }

            if (dataBeforeChange.codeName !== values.codeName){
                data.append("tag", values.codeName);
            }

            if (values.flag !== ''){
                data.append("image", values.flag);
            }

            putU(`admin/translation/language/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    if (err.response.data.message === 'Язык с таким именем или тэгом уже существует') {
                        showAlert('error', 'Язык с таким именем или тэгом уже существует');
                    } else {
                        showAlert('error', 'Ошибка сервера');
                    }
                })
                .finally(() => {
                    setSubmitDisabled(false);
                    setIsShowLoader(false);
                })
            ;
        }
    };

    useEffect(() => {
        setIsLoaded(true)

        getU(`admin/translation/language/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {

                    let vals = {
                        name: resp.data.language.name,
                        codeName: resp.data.language.tag
                    };

                    let beforeChange = {
                        name: resp.data.language.name,
                        codeName: resp.data.language.tag
                    };

                    setDataBeforeChange(beforeChange);
                    setValues(vals)
                    setUploadedImg(`${process.env.REACT_APP_API_URL}/uploads/${resp.data.language.image}`);
                }
            })
            .catch(() => {

            })
            .finally(() => {
                setIsLoaded(false)
            });
    }, []);


    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Фразы
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Фразы</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование Фразы"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    {isShowLoader && (
                                        <Box sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#ffffff',
                                            zIndex: 5
                                        }}
                                        >
                                            <CircularProgress/>
                                        </Box>
                                    )}

                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                   id={1}
                                                   onChange={(event) => imgUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                            Доступны следующие расширения: .png .jpg .svg .bmp .tga .webp
                                        </div>
                                    </div>

                                    <TextField
                                        fullWidth
                                        label="Название"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="name"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Код языка"
                                        margin="normal"
                                        name="codeName"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.codeName}
                                        variant="outlined"
                                        error={errors.codeName}
                                    />

                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>


                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default LanguageEdit;
