import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, Typography, Input, TextField, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useParams, useNavigate} from 'react-router-dom';
import UserFormEdit from '../../../components/users/UserFormEdit';
import {useGet, usePut} from "../../../API/request";
import {BallTriangle} from "react-loader-spinner";

const PhrasesEdit = () => {

    const {id} = useParams();
    const navigate = useNavigate();

    const getU = useGet();
    const putU = usePut();

    const [dataBeforeChange, setDataBeforeChange] = useState({});
    const [phrase, setPhrase] = useState('')
    const [campaign, setCampaign] = useState({});
    const [errors, setErrors] = useState({});

    const [isLoaded, setIsLoaded] = useState(true);
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [nothingChanged, setNothingChanged] = useState(true);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const handleChange = (event) => {
        setNothingChanged(false);

        setCampaign({
            ...campaign,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 2500);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};


        setErrors(formErrors);
        return validComplete;
    };

    const submit = async () => {
        if (nothingChanged) {
            showAlert('error', 'Нет изменений');
            return;
        }

        if (validate()) {
            setSubmitDisabled(true);
            setIsShowLoader(true);

            const data = {
                campaign
            }

            putU(`admin/translation/phrase/${id}`, data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Данные успешно обновленны');
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    showAlert('error', 'Ошибка сервера');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                    setIsShowLoader(false);
                })
            ;
        }
    };

    useEffect(() => {
        setIsLoaded(true)
        getU(`admin/translation/phrase/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    let languages = resp.data.phrase.languages;

                    setPhrase(resp.data.phrase.value)
                    setDataBeforeChange(languages);
                    setCampaign(languages)
                }
            })
            .catch(() =>{

            })
            .finally(() =>{
                setIsLoaded(false)
            });
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Edit user</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Фразы
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Фразы</li>
                    <li>/</li>
                    <li>Редактирование</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                <Container maxWidth={false}>
                    <Box sx={{
                        paddingBottom: 8
                    }}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Редактирование Фразы"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    {isShowLoader && (
                                        <Box sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#ffffff',
                                            zIndex: 5
                                        }}
                                        >
                                            <CircularProgress/>
                                        </Box>
                                    )}

                                    <TextField
                                        disabled
                                        fullWidth
                                        label="Переводимая фраза"
                                        margin="normal"
                                        name="firstName"
                                        onChange={handleChange}
                                        value={phrase}
                                    />

                                    {
                                        Object.entries(campaign).map(([key, values], index) => (
                                            <TextField
                                                fullWidth
                                                label={key}
                                                margin="normal"
                                                name={key}
                                                onChange={handleChange}
                                                type="text"
                                                value={values}
                                                variant="outlined"
                                                error={errors.firstName}
                                            />
                                        ))
                                    }
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PhrasesEdit;
