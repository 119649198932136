import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/confirm";
import {BallTriangle} from "react-loader-spinner";

const UserList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [users, setUsers] = useState([]);
    const [filterEmail, setFilterEmail] = useState('');
    const [tradeUrl, setTradeUrl] = useState('');


    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);


    const loadUsers = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `admin/users?page=${page + 1}&limit=${limit}`;

        if (filterEmail !== '') {
            endpoint += `&email=${filterEmail}`;
        }

        if (tradeUrl !== '') {
            // endpoint += `&trade_url=${tradeUrl}`;
            endpoint += `&trade_url=${escape(tradeUrl)}`;
        }
        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {

                    setUsers(resp.data.users);
                    setCount(resp.data.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch((err) => {
                console.log(err.response)
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() =>{
                setIsLoaded(false)
            });


    };

    const loadUsersWithArgParams = (searchParams) => {
        setIsDataLoading(true);
        setIsLoaded(true)

        let endpoint = `admin/users?page=${page + 1}&limit=${limit}`;

        if (searchParams.email !== '') {
            endpoint += `&email=${searchParams.email}`;
        }

        if (searchParams.trade_url !== '') {
            endpoint += `&trade_url=${escape(searchParams.trade_url)}`;
        }

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    setUsers(resp.data.users);
                    setCount(resp.data.allCount || 0);
                }
                setIsDataLoading(false);
            })
            .catch(() => {
                setUsers([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() =>{
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };

    const handleFilterEmail = (event) => {
        setFilterEmail(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: '',
                trade_url: tradeUrl,
            });
        }
    };

    const handleFilterTradeUrl = (event) => {
        setTradeUrl(event.target.value);

        if (event.target.value === '') {
            loadUsersWithArgParams({
                email: filterEmail,
                trade_url: "",
            });
        }
    };


    const handleFilter = () => {
        loadUsers();
    };


    useEffect(() => {
        loadUsers();
    }, [page, limit]);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Пользователи
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Email"
                                                        margin="normal"
                                                        name="filterEmail"
                                                        onChange={handleFilterEmail}
                                                        type="text"
                                                        value={filterEmail}
                                                        variant="outlined"
                                                        style={{marginRight: 10}}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Trade Url"
                                                        margin="normal"
                                                        name="tradeUrl"
                                                        onChange={handleFilterTradeUrl}
                                                        type="text"
                                                        value={tradeUrl}
                                                        variant="outlined"
                                                        style={{marginRight: 10}}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilter}
                                                        sx={{mt: 2, mb: 1, width: 180}}
                                                    >
                                                        Применить
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell >
                                                                Avatar
                                                            </TableCell>
                                                            <TableCell >
                                                                Login
                                                            </TableCell>
                                                            <TableCell >
                                                                Email
                                                            </TableCell>
                                                            <TableCell>
                                                                Trade url
                                                            </TableCell>
                                                            <TableCell>
                                                                Очки
                                                            </TableCell>
                                                            <TableCell>
                                                                Серебро
                                                            </TableCell>
                                                            <TableCell>
                                                                Золото
                                                            </TableCell>
                                                            <TableCell>
                                                                Алмаз
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {users.map((user) => (
                                                            <TableRow hover key={user.id}>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Avatar
                                                                            src={
                                                                                user.avatar ?
                                                                                    `${user.avatar}`
                                                                                    :
                                                                                    ''
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.login || "---"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.email || "---"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.trade_url || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user.points || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {`${user.gives.silver}` || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {`${user.gives.gold}`  || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {`${user.gives.diamond}` || '---'}
                                                                </TableCell>
                                                                <TableCell sx={{width: 80}}>
                                                                    <Link to={`/app/user/${user.id}`}>
                                                                        <Button color="primary" variant="contained">
                                                                            Информация
                                                                        </Button>
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell sx={{width: 80}}>
                                                                    <Link
                                                                        to={`/app/user/edit/${user.id}`}
                                                                        style={user?.role?.role_id === 3 ? {pointerEvents: 'none'} : {}}>
                                                                        <Button color="primary"
                                                                                variant="contained"
                                                                                disabled={ user?.role?.user_role === "Admin"}
                                                                        >
                                                                            Редактирование
                                                                        </Button>
                                                                    </Link>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={<span>Кол-во строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default UserList;
