import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button, Card, CardHeader, Divider, CardContent, CircularProgress, Typography, Input, TextField, Alert
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {useNavigate} from 'react-router-dom';
import UserFormCreate from '../../../components/users/UserFormCreate';
import React, {useState} from "react";
import {usePost} from "../../../API/request";

const LanguageAdd = () => {

    const navigate = useNavigate();

    const postU = usePost();

    const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
    const [values, setValues] = useState({
        name: '',
        nameCode: '',
        flag: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        nameCode: false,
        flag: false
    });

    const [isShowLoader, setIsShowLoader] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const imgUploaded = (event) => {
        setUploadedImg(URL.createObjectURL(event.target.files[0]));
        setValues({
            ...values,
            flag: event.target.files[0]
        });
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        setErrors({
            ...errors,
            [event.target.name]: false
        });
    };

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const validate = () => {
        let validComplete = true;
        let formErrors = {...errors};

        if (values.name.trim() === '') {
            validComplete = false;
            formErrors.name = true;
        }

        if (values.nameCode.trim() === '') {
            validComplete = false;
            formErrors.nameCode = true;
        }

        setErrors(formErrors);
        return validComplete;
    };

    const clearForm = () => {
        let vals = {...values};

        for (let key in vals) {
            vals[key] = '';
        }

        setValues(vals);
    };

    const submit = async () => {
        if (validate()) {
            setIsShowLoader(true);
            setSubmitDisabled(true);

            let data = new FormData();

            data.append("name", values.name);

            data.append("tag", values.nameCode);


            if (values.flag !== '') {
                data.append("image", values.flag);
            }

            postU('admin/translation/language', data)
                .then((resp) => {
                    if (resp.status === 'success') {
                        showAlert('success', 'Вы успешно добавили новый язык');
                        clearForm();
                    } else {
                        showAlert('error', 'Ошибка');
                    }
                })
                .catch((err) => {
                    console.log(err.response)
                    if (err.response.data.message === 'Язык с таким именем или тэгом уже существует') {
                        showAlert('error', 'Язык с таким именем или тэгом уже существует');
                    } else {
                        showAlert('error', 'Ошибка сервера');
                    }
                })
                .finally(() => {
                    setIsShowLoader(false);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Create new language</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Язык
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Язык</li>
                    <li>/</li>
                    <li>Добавление</li>
                </ul>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '85%'}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 2}}>
                        <form>
                            <Card>
                                <CardHeader
                                    title="Создание нового языка"
                                />
                                <Divider/>
                                <CardContent sx={{position: 'relative'}}>
                                    {isShowLoader && (
                                        <Box sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: '#ffffff',
                                            zIndex: 5
                                        }}
                                        >
                                            <CircularProgress/>
                                        </Box>
                                    )}

                                    <div className="itemWrapper">
                                        <div className="container">
                                            <input accept="xlsx/*" type="file" style={{display: 'none'}}
                                                   id={1}
                                                   onChange={(event) => imgUploaded(event, 1)}/>
                                            <label htmlFor={1}>
                                                <img src={uploadedImg} className="itemImg"/>
                                                <div className="middle"/>
                                            </label>
                                        </div>
                                        <div className="help-text">
                                            Доступны следующие расширения: .png .jpg .svg .bmp .tga .webp
                                        </div>
                                    </div>


                                    <TextField
                                        fullWidth
                                        label="Название языка"
                                        margin="normal"
                                        name="name"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.name}
                                        variant="outlined"
                                        error={errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Код языка"
                                        margin="normal"
                                        name="nameCode"
                                        onChange={handleChange}
                                        type="text"
                                        value={values.nameCode}
                                        variant="outlined"
                                        error={errors.nameCode}
                                    />
                                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                        {alert.txt}
                                    </Alert>
                                </CardContent>
                                <Divider/>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', p: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={submit}
                                        disabled={submitDisabled}
                                    >
                                        Создать
                                    </Button>
                                </Box>
                            </Card>
                        </form>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default LanguageAdd;
