import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Avatar,
    Container,
    Button,
    Card,
    CardContent,
    Divider,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Typography, Alert, TextField, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {makeStyles} from '@material-ui/styles';
import {Link as RouterLink, useParams, useNavigate, Link} from 'react-router-dom';
import {useGet, usePatch, usePut} from '../../API/request';
import '../../styles/All.css'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {BallTriangle} from "react-loader-spinner";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";


const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        padding: 10,
    },
    wrapAvatar: {
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10
    },
    wrapInner: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 120,
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        padding: 10,
    },
    text: {
        fontSize: 18,
        fontFamily: 'Roboto',
    },
    panel: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#5664d21c'
    },
    panelInCareington: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#79b3ff1c',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    }
});

const UserInfo = () => {

    const navigate = useNavigate();
    const getU = useGet();
    const putU = usePut()
    const patchU = usePatch();
    const {id} = useParams();
    const styles = useStyles();

    const [isLoaded, setIsLoaded] = useState(true);

    const [user, setUser] = useState({});
    const [challenger, setChallenger] = useState({});
    const [legend, setLegend] = useState({});
    const [champions, setChampions] = useState({});

    const [buttonDisable, setButtonDisable] = useState(false);
    const [isEditCampions, setIsEditCampions] = useState(false);

    const [teams, setTeams] = useState([])

    const [values, setValues] = useState({
        firstOne: "Пусто",
        secondOne: "Пусто",

        firstTwo: "Пусто",
        secondTwo: "Пусто",

        firstThree: "Пусто",
        secondThree: "Пусто",

        firstFour: "Пусто",
        secondFour: "Пусто",

        firstFive: "Пусто",
        secondFive: "Пусто",

        firstSix: "Пусто",
        secondSix: "Пусто",

        firstSeven: "Пусто",
        secondSeven: "Пусто",

        eight: "Пусто",
    });

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });
    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setButtonDisable(false);
        }, 3000);
    };


    const handleChange = (event) => {

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

    };

    const saveEdit = () => {
        setButtonDisable(true)

        const campaign = {
            "1/4": [
                {
                    id: 1,
                    team_id_passed: values.firstFive !== "Пусто" ? values.firstFive : null
                },
                {
                    id: 2,
                    team_id_passed: values.secondFive !== "Пусто" ? values.secondFive : null
                },
                {
                    id: 3,
                    team_id_passed: values.firstSix !== "Пусто" ? values.firstSix : null
                },
                {
                    id: 4,
                    team_id_passed: values.secondSix !== "Пусто" ? values.secondSix : null
                },
            ],
            "1/2": [
                {
                    id: 5,
                    team_id_passed: values.firstSeven !== "Пусто" ? values.firstSeven : null
                },
                {
                    id: 6,
                    team_id_passed: values.secondSeven !== "Пусто" ? values.secondSeven : null
                }
            ],
            "1/1": [
                {
                    id: 7,
                    team_id_passed: values.eight !== "Пусто" ? values.eight : null
                }
            ],
        }

        const data = {
            campaign
        }

        patchU(`admin/championsGrid?userId=${user.id}`, data)
            .then((response) => {
                if (response.status === 'success') {
                    showAlert('success', 'Данные успешно обновленны');
                    loadData();
                }
            })
            .catch((err) => {
                if (err.response.data.message === "Этап уже завершен, результаты проставлены"){
                    showAlert('error', 'Этап уже завершен, результаты проставлены');
                } else if (err.response.data.message === "Не верно проставленный порядок времени") {
                    showAlert('error', 'Не верно проставленный порядок времени');
                } else {
                    showAlert('error', 'Ошибка сервера');
                }
            })
            .finally(() => {

            })
    }

    const loadData = () =>{
        setIsLoaded(true)

        getU('team?limit=36&page=1')
            .then((response) => {
                setTeams(response.data.teams)
            })
            .catch((e) => {
                showAlert('error', 'Произошла ошибка при загрузке команд');
            })
            .finally(() => {

            })


        getU(`admin/user/${id}`)
            .then((resp) => {
                if (resp.status === 'success') {
                    console.log(resp.data.user.bets.play_off)
                    setUser(resp.data.user)
                    setChallenger(resp.data.user.bets.applicants)
                    setChampions(resp.data.user.bets.play_off)
                    setLegend(resp.data.user.bets.legends)

                    const teams = resp.data.user.bets.play_off;

                    const state = {
                        firstOne: teams["1/4"].find((item) => item.mesh_match_id === 1)?.team_id_one
                            ? teams["1/4"].find((item) => item.mesh_match_id === 1)?.team_id_one?.id
                            : "Пусто",
                        secondOne: teams["1/4"].find((item) => item.mesh_match_id === 1)?.team_id_two
                            ? teams["1/4"].find((item) => item.mesh_match_id === 1)?.team_id_two?.id
                            : "Пусто",

                        firstTwo: teams["1/4"].find((item) => item.mesh_match_id === 2)?.team_id_one
                            ? teams["1/4"].find((item) => item.mesh_match_id === 2)?.team_id_one?.id
                            : "Пусто",
                        secondTwo: teams["1/4"].find((item) => item.mesh_match_id === 2)?.team_id_two
                            ? teams["1/4"].find((item) => item.mesh_match_id === 2)?.team_id_two?.id
                            : "Пусто",

                        firstThree: teams["1/4"].find((item) => item.mesh_match_id === 3)?.team_id_one
                            ? teams["1/4"].find((item) => item.mesh_match_id === 3)?.team_id_one?.id
                            : "Пусто",
                        secondThree: teams["1/4"].find((item) => item.mesh_match_id === 3)?.team_id_two
                            ? teams["1/4"].find((item) => item.mesh_match_id === 3)?.team_id_two?.id
                            : "Пусто",

                        firstFour: teams["1/4"].find((item) => item.mesh_match_id === 4)?.team_id_one
                            ? teams["1/4"].find((item) => item.mesh_match_id === 4)?.team_id_one?.id
                            : "Пусто",
                        secondFour: teams["1/4"].find((item) => item.mesh_match_id === 4)?.team_id_two
                            ? teams["1/4"].find((item) => item.mesh_match_id === 4)?.team_id_two?.id
                            : "Пусто",

                        firstFive: teams["1/2"].find((item) => item.mesh_match_id === 5)?.team_id_one
                            ? teams["1/2"].find((item) => item.mesh_match_id === 5)?.team_id_one?.id
                            : "Пусто",
                        secondFive: teams["1/2"].find((item) => item.mesh_match_id === 5)?.team_id_two
                            ? teams["1/2"].find((item) => item.mesh_match_id === 5)?.team_id_two?.id
                            : "Пусто",

                        firstSix: teams["1/2"].find((item) => item.mesh_match_id === 6)?.team_id_one
                            ? teams["1/2"].find((item) => item.mesh_match_id === 6)?.team_id_one?.id
                            : "Пусто",
                        secondSix: teams["1/2"].find((item) => item.mesh_match_id === 6)?.team_id_two
                            ? teams["1/2"].find((item) => item.mesh_match_id === 6)?.team_id_two?.id
                            : "Пусто",

                        firstSeven: teams["1/1"].find((item) => item.mesh_match_id === 7)?.team_id_one
                            ? teams["1/1"].find((item) => item.mesh_match_id === 7)?.team_id_one?.id
                            : "Пусто",
                        secondSeven: teams["1/1"].find((item) => item.mesh_match_id === 7)?.team_id_two
                            ? teams["1/1"].find((item) => item.mesh_match_id === 7)?.team_id_two?.id
                            : "Пусто",

                        eight: teams.final.team_id_winner
                            ? teams.final.team_id_winner.id
                            : "Пусто",
                    }


                    setValues(state)
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при загрузке информации о пользователе');
            })
            .finally(() => {
                setIsLoaded(false)
            });
    }

    useEffect(() => {
        loadData()
    }, []);

    console.log(values)
    console.log(teams)

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>User</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Пользователи
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Пользователи</li>
                    <li>/</li>
                    <li>Информация</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', pt: 3, pb: 1}}>
                <Container maxWidth={false}>
                    <Card>

                        <CardContent sx={{p: 3}}>
                            <PerfectScrollbar>
                                <div className="wrapAvatar">
                                    <div className="avatar-block">
                                        <Avatar src={user.avatar} className="avatar"/>
                                    </div>
                                    <div className="info-block">
                                        <div className="wrap">
                                            <div className="label">
                                                ID:
                                            </div>
                                            <div className="text">
                                                {user?.id || '---'}
                                            </div>
                                        </div>
                                        <div className="wrap">
                                            <div className="label">
                                                Login
                                            </div>
                                            <div className="text">
                                                {user?.login || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Email:
                                            </div>
                                            <div className="text">
                                                {user?.email || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Role:
                                            </div>
                                            <div className="text">
                                                {user?.role?.user_role_russia || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Trade Url:
                                            </div>
                                            <div className="text">
                                                {user?.trade_url || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Серебно
                                            </div>
                                            <div className="text">
                                                {`${user?.gives?.silver}` || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Золото
                                            </div>
                                            <div className="text">
                                                {`${user?.gives?.gold}` || '---'}
                                            </div>
                                        </div>

                                        <div className="wrap">
                                            <div className="label">
                                                Алмаз
                                            </div>
                                            <div className="text">
                                                {`${user?.gives?.diamond}` || '---'}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </PerfectScrollbar>
                        </CardContent>
                    </Card>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 600}}>
                                    <Typography
                                        sx={{p: 2}}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        Претенденты
                                    </Typography>
                                    {user?.bets?.applicants
                                        ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Команда
                                                    </TableCell>
                                                    <TableCell>
                                                        Результат
                                                    </TableCell>
                                                    <TableCell>
                                                        Прогноз
                                                    </TableCell>
                                                    <TableCell>
                                                        Угада/Не угадал
                                                    </TableCell>
                                                    <TableCell>
                                                        Очки
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    challenger.map((item) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                        <Avatar
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item?.team_id?.logo}`}
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `${item?.result?.matches_win} / ${item?.result?.matches_lose}`
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        item?.bet?.passed ? `Поставил, что команда пройдет` : `${item?.bet?.matches_win} / ${item?.bet?.matches_lose}`
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `${item?.bet?.right}`
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `+ ${item?.bet?.point}`
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        : <div className="data_no_loaded">
                                            У пользователя нет ставок в данном этапе
                                        </div>
                                    }

                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            <Box sx={{backgroundColor: 'background.default', py: 1}}>
                <Container maxWidth={false}>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <Box sx={{minWidth: 600}}>
                                    <Typography
                                        sx={{p: 2}}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        Легенды
                                    </Typography>
                                    {user?.bets?.legends
                                        ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Команда
                                                    </TableCell>
                                                    <TableCell>
                                                        Результат
                                                    </TableCell>
                                                    <TableCell>
                                                        Прогноз
                                                    </TableCell>
                                                    <TableCell>
                                                        Угада/Не угадал
                                                    </TableCell>
                                                    <TableCell>
                                                        Очки
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    legend.map((item) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Box sx={{alignItems: 'center', display: 'flex'}}>
                                                                        <Avatar
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item?.team_id?.logo}`}
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `${item?.result?.matches_win} / ${item?.result?.matches_lose}`
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        item?.bet?.passed ? `Поставил, что команда пройдет` : `${item?.bet?.matches_win} / ${item?.bet?.matches_lose}`
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `${item?.bet?.right}`
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        `+ ${item?.bet?.point}`
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                        : <div className="data_no_loaded">
                                            У пользователя нет ставок в данном этапе
                                        </div>
                                    }

                                </Box>
                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>

            {
                isEditCampions
                    ? <Box sx={{backgroundColor: 'background.default', minHeight: '85%', py: 3}}>
                        <Container maxWidth={false}>
                            <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none'}}>
                                {alert.txt}
                            </Alert>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Box sx={{marginLeft: 2, marginBottom: 3}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setIsEditCampions(!isEditCampions)}
                                    >
                                        Назад
                                    </Button>
                                </Box>
                                <Box sx={{marginLeft: 2, marginBottom: 3}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={saveEdit}
                                    >
                                        Сохранить
                                    </Button>
                                </Box>
                            </Box>
                            <Card>
                                <PerfectScrollbar>
                                    <Box sx={{minWidth: 850}}>
                                        <div className="tournament">
                                            <div className="tournament__grid">

                                                <div className="tournament__round tournament__round--first-round">
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstOne">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="firstOne"
                                                                name="firstOne"
                                                                value={values.firstOne}
                                                                label="1/4"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem
                                                                            value={item.id}
                                                                            disabled={
                                                                                item.id === values.firstOne ||
                                                                                item.id === values.secondOne ||
                                                                                item.id === values.firstTwo ||
                                                                                item.id === values.secondTwo ||
                                                                                item.id === values.firstThree ||
                                                                                item.id === values.secondThree ||
                                                                                item.id === values.firstFour ||
                                                                                item.id === values.secondFour
                                                                            }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondOne">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="secondOne"
                                                                name="secondOne"
                                                                value={values.secondOne}
                                                                label="1/4"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstTwo">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="firstTwo"
                                                                name="firstTwo"
                                                                value={values.firstTwo}
                                                                label="firstTwo"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondTwo">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="secondTwo"
                                                                name="secondTwo"
                                                                value={values.secondTwo}
                                                                label="secondTwo"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstThree">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="firstThree"
                                                                name="firstThree"
                                                                value={values.firstThree}
                                                                label="firstThree"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondThree">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="secondThree"
                                                                name="secondThree"
                                                                value={values.secondThree}
                                                                label="secondThree"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstFour">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="firstFour"
                                                                name="firstFour"
                                                                value={values.firstFour}
                                                                label="firstFour"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondFour">1/4</InputLabel>
                                                            <Select
                                                                disabled
                                                                labelId="secondFour"
                                                                name="secondFour"
                                                                value={values.secondFour}
                                                                label="secondFour"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.map((item) => (
                                                                        <MenuItem value={item.id}
                                                                                  disabled={
                                                                                      item.id === values.firstOne ||
                                                                                      item.id === values.secondOne ||
                                                                                      item.id === values.firstTwo ||
                                                                                      item.id === values.secondTwo ||
                                                                                      item.id === values.firstThree ||
                                                                                      item.id === values.secondThree ||
                                                                                      item.id === values.firstFour ||
                                                                                      item.id === values.secondFour
                                                                                  }
                                                                        >
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="tournament__round">
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstFive">1/2</InputLabel>
                                                            <Select
                                                                labelId="firstFive"
                                                                name="firstFive"
                                                                value={values.firstFive}
                                                                label="firstFive"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {teams.filter((item) => item.id === values.firstOne || item.id === values.secondOne).map((item) => (
                                                                    <MenuItem value={item.id}>
                                                                        <div className="tournament__itemContainer">
                                                                            <img
                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                alt=""
                                                                                className="menuItemImg"/>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </div>
                                                                    </MenuItem>
                                                                ))
                                                                }

                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondFive">1/2</InputLabel>
                                                            <Select
                                                                labelId="secondFive"
                                                                name="secondFive"
                                                                value={values.secondFive}
                                                                label="secondFive"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.filter((item) => item.id === values.firstTwo || item.id === values.secondTwo).map((item) => (
                                                                        <MenuItem value={item.id}>
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstSix">1/2</InputLabel>
                                                            <Select
                                                                labelId="firstSix"
                                                                name="firstSix"
                                                                value={values.firstSix}
                                                                label="firstSix"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.filter((item) => item.id === values.firstThree || item.id === values.secondThree).map((item) => (
                                                                        <MenuItem value={item.id}>
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondSix">1/2</InputLabel>
                                                            <Select
                                                                labelId="secondSix"
                                                                name="secondSix"
                                                                value={values.secondSix}
                                                                label="secondSix"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.filter((item) => item.id === values.firstFour || item.id === values.secondFour).map((item) => (
                                                                        <MenuItem value={item.id}>
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="tournament__round tournament__round--final">
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstSeven">Финал</InputLabel>
                                                            <Select
                                                                labelId="firstSeven"
                                                                name="firstSeven"
                                                                value={values.firstSeven}
                                                                label="firstSeven"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.filter((item) => item.id === values.firstFive || item.id === values.secondFive).map((item) => (
                                                                        <MenuItem value={item.id}>
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="secondSeven">Финал</InputLabel>
                                                            <Select
                                                                labelId="secondSeven"
                                                                name="secondSeven"
                                                                value={values.secondSeven}
                                                                label="secondSeven"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.filter((item) => item.id === values.firstSix || item.id === values.secondSix).map((item) => (
                                                                        <MenuItem value={item.id}>
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>

                                                <div className="tournament__round tournament__round--winner">
                                                    <div className="tournament__match">
                                                        <FormControl fullWidth className="tournament__match__teamE"
                                                                     size="small">
                                                            <InputLabel id="firstEight">Победитель</InputLabel>
                                                            <Select
                                                                labelId="Победитель"
                                                                name="eight"
                                                                value={values.eight}
                                                                label="Победитель"
                                                                onChange={handleChange}
                                                            >
                                                                <MenuItem value="Пусто">
                                                                    <div className="tournament__itemContainer">
                                                                        Пусто
                                                                    </div>
                                                                </MenuItem>
                                                                {
                                                                    teams.filter((item) => item.id === values.firstSeven || item.id === values.secondSeven).map((item) => (
                                                                        <MenuItem value={item.id}>
                                                                            <div className="tournament__itemContainer">
                                                                                <img
                                                                                    src={`${process.env.REACT_APP_API_URL}/uploads/${item.logo}`}
                                                                                    alt=""
                                                                                    className="menuItemImg"/>
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </div>
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>
                                </PerfectScrollbar>

                            </Card>

                        </Container>
                    </Box>
                    : <Box sx={{backgroundColor: 'background.default', py: 3}}>
                        <Container maxWidth={false}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Box sx={{marginLeft: 2}}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setIsEditCampions(!isEditCampions)}
                                    >
                                        Редактировать чемпионов
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{pt: 3}}>
                                <Card>
                                    <PerfectScrollbar>
                                        <Box sx={{minWidth: 850}}>
                                            <Typography
                                                sx={{p: 2}}
                                                variant="h6"
                                                id="tableTitle"
                                                component="div"
                                            >
                                                Чемпионы
                                            </Typography>
                                            {user?.bets?.play_off
                                                ?
                                                <>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell sx={{textAlign: 'center', paddingRight: 4}}>

                                                                </TableCell>
                                                                <TableCell sx={{textAlign: 'center'}}>
                                                                    +
                                                                    {
                                                                        champions["1/4"]?.reduce((total, points) => total + points?.bet?.point, 0)
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{textAlign: 'center'}}>
                                                                    +
                                                                    {
                                                                        champions["1/2"]?.reduce((total, points) => total + points?.bet?.point, 0)
                                                                    }
                                                                </TableCell>
                                                                <TableCell sx={{textAlign: 'center'}}>
                                                                    +
                                                                    {
                                                                        champions["1/1"]?.reduce((total, points) => total + points?.bet?.point, 0)
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                    </Table>
                                                    <div className="tournament">
                                                        <div className="tournament__grid">

                                                            {/*1/4*/}
                                                            <div
                                                                className="tournament__round tournament__round--first-round">
                                                                {
                                                                    champions["1/4"]?.map((item) => (

                                                                        <div className="tournament__match">

                                                                            <div className={`tournament__match__team`}>

                                                                                {
                                                                                    item.team_id_one
                                                                                        ? <>
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_id_one.logo}`}
                                                                                                alt=""/>
                                                                                            <div>
                                                                                                {item.team_id_one.name}
                                                                                            </div>
                                                                                        </>
                                                                                        : <>
                                                                                            <img
                                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                                alt=""/>
                                                                                            <div>
                                                                                                Пусто
                                                                                            </div>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                            <div className={`tournament__match__team`}>
                                                                                {
                                                                                    item.team_id_two
                                                                                        ? <>
                                                                                            <img
                                                                                                src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_id_two.logo}`}
                                                                                                alt=""/>
                                                                                            <div>
                                                                                                {item.team_id_two.name}
                                                                                            </div>
                                                                                        </>
                                                                                        : <>
                                                                                            <img
                                                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGFY-HM-NlGu8YCwA2fg4TLINfApAZnjzbg&usqp=CAU"
                                                                                                alt=""/>
                                                                                            <div>
                                                                                                Пусто
                                                                                            </div>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                }
                                                            </div>
                                                            {/*1/2*/}
                                                            <div className="tournament__round">
                                                                <div className="tournament__match">
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/4"][0]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>

                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions["1/2"][0].team_id_one.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions["1/2"][0].team_id_one.name}
                                                                        </div>

                                                                    </div>
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/4"][1]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions["1/2"][0].team_id_two.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions["1/2"][0].team_id_two.name}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="tournament__match">
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/4"][2]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>

                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions["1/2"][1].team_id_one.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions["1/2"][1].team_id_one.name}
                                                                        </div>

                                                                    </div>
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/4"][3]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions["1/2"][1].team_id_two.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions["1/2"][1].team_id_two.name}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*1/1*/}
                                                            <div className="tournament__round tournament__round--final">
                                                                <div className="tournament__match">
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/2"][0]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>

                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions["1/1"][0].team_id_one.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions["1/1"][0].team_id_one.name}
                                                                        </div>

                                                                    </div>
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/2"][1]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions["1/1"][0].team_id_two.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions["1/1"][0].team_id_two.name}
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {/*final*/}
                                                            <div className="tournament__round tournament__round--winner">
                                                                <div className="tournament__match">
                                                                    <div
                                                                        className={`tournament__match__team ${champions["1/1"][0]?.bet?.right ? 'tournament__match__team_success' : 'tournament__match__team_lose'}`}>
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${champions?.final?.team_id_winner?.logo}`}
                                                                            alt=""/>
                                                                        <div>
                                                                            {champions?.final?.team_id_winner.name}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : <div className="data_no_loaded">
                                                    У пользователя нет ставок в данном этапе
                                                </div>
                                            }

                                        </Box>
                                    </PerfectScrollbar>
                                </Card>
                            </Box>
                        </Container>
                    </Box>
            }

        </>
    );
};

export default UserInfo;
