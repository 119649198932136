import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../../API/request';
import {useConfirm} from "../../../components/confirm";
import {BallTriangle} from "react-loader-spinner";

const LanguageList = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [languages, setLanguages] = useState([]);


    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();

    const loadLang = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        getU('translation?page=1&limit=20')
            .then((resp) => {
                if (resp.status === 'success') {
                    setLanguages(resp.data.languages);
                }
            })
            .catch((err) => {
                // console.log(err.response)
                setLanguages([]);
            })
            .finally(() =>{
                setIsLoaded(false)
                setIsDataLoading(false);

            });


    };

    const onDelete = (id) => {

        confirm({
            title: 'Удаление',
            content: 'После удаления язык и все фразы на данном языке будут удалены',
            onConfirm: () => {
                deleteU(`admin/translation/language/${id}`)
                    .then((resp) => {
                        loadLang()
                    })
                    .catch((e) => {
                        console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        loadLang();
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>language</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Языки
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="/app/language/add">
                                            <Button color="primary" variant="contained">
                                                Добавить Язык
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 400}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                Флаг
                                                            </TableCell>
                                                            <TableCell>
                                                                Код языка
                                                            </TableCell>
                                                            <TableCell>
                                                                Название языка
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {languages.map((item) => (
                                                            <TableRow hover key={item.id}>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 90,
                                                                                maxHeight: { xs: 233, md: 167 },
                                                                                maxWidth: { xs: 350, md: 250 },
                                                                            }}
                                                                            alt="img"
                                                                            src={`${process.env.REACT_APP_API_URL}/uploads/${item.image}`}
                                                                        />

                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.tag || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.name || '---'}
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}}>
                                                                    <Link to={`/app/language/edit/${item.id}`}>
                                                                        <Button color="primary"
                                                                                variant="contained"
                                                                        >
                                                                            Редактировать
                                                                        </Button>
                                                                    </Link>
                                                                </TableCell>
                                                                <TableCell sx={{width: 100}}>
                                                                    <Button color="primary"
                                                                            variant="contained"
                                                                            onClick={() => onDelete(item.id)}
                                                                    >
                                                                        Удалить
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};



export default LanguageList;
