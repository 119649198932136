import React, {useState, useEffect, useRef} from 'react';
import {Helmet} from 'react-helmet';
import {
    Box,
    Container,
    Button,
    Typography, Divider, CardHeader, Grid, Alert, TextField
} from '@material-ui/core';
import {Link as RouterLink, Link, useNavigate} from 'react-router-dom';
import Card from '@material-ui/core/Card';
import '../../styles/Stages/style.css'
import {BallTriangle} from "react-loader-spinner";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {put, useGet, usePost, usePut} from "../../API/request";



const Challenger = () => {

    const navigate = useNavigate();
    const getU = useGet()
    const putU = usePut();

    const [isLoaded, setIsLoaded] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [teams, setTeams] = useState([])

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

            setSubmitDisabled(false);
        }, 3000);
    };

    const saveEdit = () => {
        setSubmitDisabled(true);

        let map = teams.map((item) => {
            return ({
                id: item.team_id,
                matches_win: item.matches_win,
                matches_lose: item.matches_lose,
                passed: item.passed
            })
        });

        putU('admin/result/1', {teams: map})
            .then((response) => {
                if (response.status === 'success') {
                    showAlert('success', 'Данные успешно обновленны');
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.response.data.message === "Этап уже завершен, результаты проставлены"){
                    showAlert('error', 'Этап уже завершен, результаты проставлены');
                } else {
                    showAlert('error', 'Ошибка сервера');
                }
            })
            .finally(() => {

            })
    }


    const getTeams = () => {
        setIsLoaded(true)

        getU('stage/1')
            .then((response) => {
                if (response.status === 'success') {
                    console.log(response.data.teams)
                    setTeams(response.data.teams)
                }
            })
            .catch(() => {
                showAlert('error', 'Произошла ошибка при попытке получить команды');
            })
            .finally(() => {
                setIsLoaded(false)
            })
    }


    useEffect(() => {
        getTeams()
    }, []);

    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    const onChangeWin = (event) => {
        const min = 0;
        const max = 3;

        let value = parseInt(event.target.value, 10);

        if (value >= max) value = max;
        if (value <= min) value = min;

        const newArr = teams.map(obj => {
            if (Number(obj.id) === Number(event.target.name)) {
                return {...obj, matches_win: value};
            }

            return obj;
        });
        setTeams(newArr);
    }

    const onChangeLose = (event) => {
        const min = 0;
        const max = 3;

        let value = parseInt(event.target.value, 10);

        if (value >= max) value = max;
        if (value <= min) value = min;

        const newArr = teams.map(obj => {
            if (Number(obj.id) === Number(event.target.name)) {
                return {...obj, matches_lose: value};
            }

            return obj;
        });
        setTeams(newArr);
    }

    const onChangePassed = (id) => {

        const newArr = teams.map(obj => {
            if (Number(obj.id) === Number(id)) {
                return {...obj, passed: !obj.passed};
            }

            return obj;
        });

        setTeams(newArr);
    }

    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>
            <Box className="headerWrapper">
                <Box className="headerTitle">
                    <Button startIcon={<ChevronLeft/>} onClick={() => navigate(-1)}>
                        Назад
                    </Button>
                    Претенденты
                </Box>
                <ul className="headerList">
                    <li onClick={() => navigate(-1)}>Претенденты</li>
                    <li>/</li>
                    <li>Счет</li>
                </ul>
            </Box>

            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{marginBottom: 15, display: alert.isVisible ? 'flex' : 'none'}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
                        <Box onClick={() => console.log("click")}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={saveEdit}
                                disabled={submitDisabled}
                            >
                                Сохранить
                            </Button>
                        </Box>
                    </Box>
                    <Card>
                        <Box className="cardMainBlock">

                            <Typography
                                color="textPrimary"
                                variant="h5"
                                className="cardBlockTitle"
                            >
                                Проставьте оценки командам
                            </Typography>

                            <Divider/>

                            <Box className="cardMainItemsScore">
                                {
                                    teams <= 0
                                        ? <Box className="cardMainNoData">
                                            <Typography className="noDataFirstDesc">
                                                На данные момент список команд пуст.
                                            </Typography>
                                        </Box>
                                        : teams?.map((item, index) =>
                                            <div className="itemWrapper">
                                                <div className="itemNameInputBlock">
                                                    <img src={`${process.env.REACT_APP_API_URL}/uploads/${item.team_logo}`}
                                                         className="itemImg"/>
                                                    <div className="cardBlockInputs">
                                                        <TextField
                                                            sx={{minWidth: 200}}
                                                            fullWidth
                                                            type="number"
                                                            variant="outlined"
                                                            label="Количество побед"
                                                            margin="normal"

                                                            defaultValue={0}
                                                            value={item.matches_win}
                                                            name={item.id}
                                                            onChange={onChangeWin}
                                                            inputProps={{min: 0, max: 3}}
                                                        />
                                                        <TextField
                                                            sx={{minWidth: 200}}
                                                            fullWidth
                                                            label="Количество поражений"
                                                            margin="normal"
                                                            type="number"
                                                            variant="outlined"

                                                            defaultValue={0}
                                                            value={item.matches_lose}
                                                            name={item.id}
                                                            onChange={onChangeLose}
                                                            inputProps={{min: 0, max: 3}}
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={item.passed}
                                                                    onChange={() => onChangePassed(item.id)}
                                                                    name="antoine"
                                                                />
                                                            }
                                                            label="Прошел"
                                                        />
                                                    </div>

                                                </div>
                                                <div className="itemTitle">
                                                    {
                                                        item.team_name
                                                    }
                                                </div>

                                            </div>
                                        )
                                }

                            </Box>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Challenger;
