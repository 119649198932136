import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
import {
    Box,
    Container,
    Avatar,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    TableFooter,
    TablePagination,
    TextField,
    Divider, Alert
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useDelete, useGet, usePut} from '../../../API/request';
import {useConfirm} from "../../../components/confirm";
import {BallTriangle} from "react-loader-spinner";
import '../../../styles/ResetPoints/style.css'

const ResetPoints = () => {

    const getU = useGet();
    const putU = usePut();
    const deleteU = useDelete();
    const confirm = useConfirm();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [configs, setConfigs] = useState([]);
    const [recalculateData, setRecalculateData] = useState([]);

    const [alert, setAlert] = useState({
        txt: '',
        isVisible: false,
        type: 'error'
    });

    const showAlert = (type, text) => {
        setAlert({
            txt: text,
            type,
            isVisible: true
        });

        setTimeout(() => {
            setAlert({
                txt: text,
                type,
                isVisible: false
            });

        }, 3000);
    };

    const loadConfigs = () => {
        setIsDataLoading(true);
        setIsLoaded(true)

        getU('general/settings')
            .then((resp) => {
                if (resp.status === 'success') {
                    setConfigs(resp.data.settings);
                }
            })
            .catch((err) => {
                console.log(err.response)
                setConfigs([]);
            })
            .finally(() => {
                setIsLoaded(false)
                setIsDataLoading(false);
            });

    };

    const phrasesForSettings = [
        "У всех юзеров сбросится количество очков до 0",
        "У всех юзеров удалятся присвоенные ими gives",
    ]

    const onResetAllSettings = () => {
        confirm({
            title: 'Вы уверены, что хотите сбросить все этапы?',
            content: 'Что вы удаляете/обновляете и не можете потом вернуть:',
            list: phrasesForSettings,
            onConfirm: () => {
                putU(`general/resetPointsUsers`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', 'Вы успешно сбросили все очки и гивы пользователей');
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', 'Произошла ошибка при сбросе этапов');
                        console.log(e.response)
                    });
            }
        });
    };
    const onReculcuteFirstStage = () => {
        confirm({
            title: 'Вы уверены, что хотите пересчитать результаты для пользователей в 1 эатпе?',
            onConfirm: () => {
                putU(`admin/stageResultReset?id=1`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', resp?.message);
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e?.response?.data?.message);
                        console.log(e.response)
                    });
            }
        });
    };
    const onReculcuteSecondStage = () => {
        confirm({
            title: 'Вы уверены, что хотите пересчитать результаты для пользователей в 2 эатпе?',
            onConfirm: () => {
                putU(`admin/stageResultReset?id=2`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', resp?.message);
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e?.response?.data?.message);
                        console.log(e.response)
                    });
            }
        });
    };
    const onReculcuteThirdStage = () => {
        confirm({
            title: 'Вы уверены, что хотите пересчитать результаты для пользователей в 3 эатпе?',
            onConfirm: () => {
                putU(`admin/stageResultReset?id=3`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            showAlert('success', resp?.message);
                            loadConfigs();
                        }
                    })
                    .catch((e) => {
                        showAlert('error', e?.response?.data?.message);
                        console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        loadConfigs();
    }, []);

    useEffect(() =>{
        getU('general/getBeRecalculated')
            .then((response) =>{
                console.log(response.data)
                let map = response.data.array.map((item) =>item.name_russia);
                setRecalculateData(map)
            })
            .catch(() =>{
                showAlert('error', 'Произошла ошибка при попытке взять данные для перерасчета');
            })
            .finally(() =>{

            })
    }, [])



    if (isLoaded) {
        return (
            <div className="loader">
                <BallTriangle
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Points</title>
            </Helmet>

            <Box className="headerWrapper">
                <Box className="headerTitle">
                    Сброс/пересчет очков
                </Box>
            </Box>
            <Box sx={{backgroundColor: 'background.default', minHeight: '90%', py: 3}}>

                <Container maxWidth={false}>
                    <Alert severity={alert.type} style={{display: alert.isVisible ? 'flex' : 'none', marginBottom: 20}}>
                        {alert.txt}
                    </Alert>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                        <Box>
                            <Button
                                sx={{ml: 1, mb: 1, width: 300}}
                                color="primary"
                                variant="contained"
                                onClick={onResetAllSettings}

                            >
                                Сброс очков/гивов
                            </Button>
                            <Button
                                sx={{ml: 1, mb: 1, width: 300}}
                                color="primary"
                                variant="contained"
                                onClick={onReculcuteFirstStage}

                            >
                                Пересчет очков первого этапа
                            </Button>
                            <Button
                                sx={{ml: 1, mb: 1, width: 300}}
                                color="primary"
                                variant="contained"
                                onClick={onReculcuteSecondStage}

                            >
                                Пересчет очков второго этапа
                            </Button>
                            <Button
                                sx={{ml: 1, mb: 1, width: 300}}
                                color="primary"
                                variant="contained"
                                onClick={onReculcuteThirdStage}

                            >
                                Пересчет очков третьего этапа
                            </Button>
                        </Box>

                    </Box>
                    <Box sx={{pt: 3}}>
                        <Card>
                            <PerfectScrollbar>
                                <div className="reset-points-wrapper">
                                    <div className="reset-points-title">
                                        Инструкция по пересчету очков/гивов пользователей
                                    </div>
                                    <div className="reset-points-desc">
                                        Для пересчета очков и гивов пользователя
                                        вам необходимо выполнить следующие шаги:
                                    </div>
                                    <ul className="reset-points-list">
                                        <li>1. Для начала сбросьте очки и гивы для всех пользователей,
                                            которые они получили за все этапы (кнопка "Сброс очков/гивов")</li>
                                        <li>2. Далее начинайте пересчитывать этапы, начиная с первого,
                                            до последнего этапа, который вы завершили.
                                            (кнопки "Пересчет очков первого/второго/третьего этапа")</li>
                                        <li>3. Не нажимайте сразу все этапы, дождитесь окончания пересчета
                                            текущего этапа(который вы пересчитываете)</li>
                                        <li>4. Статус выполнения пересчета можно узнать в логах</li>
                                        <li>5. Статус 500 ошибки - что-то пошло не так. Сообщение даст понять,
                                            что именно. Если оно не связанно с бд, то начисление очков юзерам еще
                                            не началось</li>
                                        <li>6. Статус 200 - все прошло успешно, появляется после окончательного
                                            пересчета очков у всех юзеров.</li>
                                    </ul>
                                </div>

                            </PerfectScrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ResetPoints;
